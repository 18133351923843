import moment from 'moment'
import { useQuery } from 'react-query'

import { DateFormat_DDMMYYHHmm } from 'constants/dateFormats'

type CurrentTimeOlockParams = {
  formatFn?: (date: Date) => string
}

const useCurrentTimeOlock = (params?: CurrentTimeOlockParams) => {
  const { formatFn } = params || {}

  const getCustomCurrentDate = () =>
    typeof formatFn === 'function'
      ? formatFn(new Date())
      : moment(new Date()).format(DateFormat_DDMMYYHHmm)

  const { data: currentDate } = useQuery(['current-olock'], getCustomCurrentDate, {
    refetchInterval: 1000,
    retry: false,
  })

  return { currentDate }
}

export default useCurrentTimeOlock
