import React, { FC, ReactNode, useMemo } from 'react'

import styled from '@emotion/styled'
import { Button, Dropdown, Avatar as AntdAvatar, Avatar } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppContext } from 'contexts/AppContext'
import { useAuthContext } from 'contexts/AuthContext'
import mq from 'utils/mediaQuery'

const StyledButton = styled(Button)((props) => ({
  padding: `0px ${props.theme.paddingSM}px !important`,
  display: 'flex',
  alignItems: 'center',
}))

const Username = styled.span((props) => ({
  fontSize: 14,
  marginLeft: props.theme.paddingXS,
}))

const WrapperButtonContainer = styled.div(() => ({
  display: 'none',
  [mq('md')]: {
    display: 'block',
  },
}))

interface AppListButtonProps {
  preffixIcon?: ReactNode
}

const StyledDropDown = styled(Dropdown)(() => ({
  '.ant-dropdown-menu': {
    maxHeight: '200px !important',
    overflowY: 'auto',
  },
}))

const AppListButton: FC<AppListButtonProps> = ({ preffixIcon }) => {
  const { user } = useAuthContext()
  const { configs } = useAppContext()
  const navigate = useNavigate()
  const { projectId } = useParams()

  const project = useMemo(
    () => (configs || []).find((cfg) => cfg.id === projectId),
    [configs, projectId],
  )

  return user && projectId ? (
    <>
      {preffixIcon}
      <WrapperButtonContainer>
        <StyledDropDown
          rootClassName="app-list-button"
          placement="bottomRight"
          menu={{
            activeKey: projectId,
            items: [
              {
                key: 'my-apps',
                label: 'My Apps',
                type: 'group',
                style: { paddingLeft: 0 },
                children: (configs || []).map((config) => ({
                  key: config.id || '',
                  label: config.miniAppName,
                  onClick: () => navigate(`/project/${config.id}`, { replace: true }),
                  icon: <Avatar src={config.appLogo || '/images/momo-icon.png'} shape="square" />,
                  style: { marginBottom: 4 },
                })),
              },
            ],
          }}
        >
          <StyledButton
            type="primary"
            size="large"
            block
            icon={
              <AntdAvatar
                src={project?.appLogo || '/images/momo-icon.png'}
                size={28}
                shape="square"
              />
            }
          >
            <Username>{project?.miniAppName || ''}</Username>
          </StyledButton>
        </StyledDropDown>
      </WrapperButtonContainer>
    </>
  ) : null
}

export default AppListButton
