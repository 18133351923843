import React from 'react'

import styled from '@emotion/styled'
import { Button, Space, Typography } from 'antd'
import { ArrowRight, Heart } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import mq, { useWindowSize } from 'utils/mediaQuery'

interface YourMiniAppProps {
  className?: string
}

const Wrapper = styled.div((props) => ({
  backgroundColor: props.theme.colorBgContainer,
  padding: `${props.theme.paddingXL}px ${props.theme.padding}px`,
  paddingBottom: props.theme.sizeXXL * 2,
  [mq('xs')]: {
    paddingBottom: props.theme.sizeXXL * 4,
  },
}))

const Container = styled.div<{ crushed?: boolean }>((props) => ({
  maxWidth: 600,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  '.heart': {
    transition: 'all 0.3s ease-in-out',
    marginBottom: 8,
    '&:hover': {
      transform: 'scale(1.4)',
    },
    animation: props.crushed ? 'heartBeat 2s ease-in-out both' : 'none',
    animationIterationCount: 'infinite',
    '@keyframes heartBeat': {
      '10%, 90%': {
        transform: 'scale(0.8)',
      },
      '20%, 80%': {
        transform: 'scale(0.9)',
      },
      '30%, 50%, 70%': {
        transform: 'scale(1.4)',
      },
      '40%, 60%': {
        transform: 'scale(1.2)',
      },
    },
  },
}))

const ImageWrapper = styled.div({
  maxWidth: 500,
  position: 'relative',
})

const Background = styled.img(() => ({
  width: '100%',
}))

const GetStartedButton = styled(Button)((props) => ({
  marginTop: props.theme.marginXXL,
  '.ant-space-item': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px !important',
  },
}))

const Logo = styled.img<{ loved?: boolean }>((props) => ({
  position: 'absolute',
  top: '53.5%',
  left: '44.5%',
  width: 40,
  [mq('xs')]: {
    width: 53,
  },
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  animation: props.loved ? 'shake 1s cubic-bezier(.36,.07,.19,.97) both' : 'none',
  animationIterationCount: 'infinite',
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)',
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },
}))

const YourMiniApp: React.FC<YourMiniAppProps> = ({ className }) => {
  const [crush, setCrush] = React.useState(false)
  const [love, setLove] = React.useState(false)
  const { breakpoint } = useWindowSize()
  const { t } = useTranslation()

  return (
    <Wrapper className={className}>
      <Container crushed={crush}>
        <Space
          direction={breakpoint === 'xxs' ? 'vertical' : 'horizontal'}
          size={breakpoint === 'xxs' ? 0 : 'middle'}
        >
          <Typography.Title level={2}>{t('appName')}</Typography.Title>
          <Heart
            className="heart"
            size={24}
            weight="fill"
            color="#C73E89"
            onMouseEnter={() => setLove(true)}
            onMouseLeave={() => setLove(false)}
          />
          <Typography.Title level={2}>{t('landing.yourBusiness')}</Typography.Title>
        </Space>
        <Typography.Paragraph style={{ fontSize: 18 }} type="secondary">
          {t('landing.yourBusinessDescription')}
        </Typography.Paragraph>
        <ImageWrapper>
          <Background src="/images/your-mini-app.png" />
          <Logo
            src="/images/your-mini-app-icon.png"
            loved={love}
            onMouseEnter={() => setCrush(true)}
            onMouseLeave={() => setCrush(false)}
          />
        </ImageWrapper>
        <Link to="dashboard/new-app">
          <GetStartedButton size="large" type="primary">
            <Space>
              {t('landing.yourBusinessCTA')}
              <ArrowRight size={18} weight="bold" />
            </Space>
          </GetStartedButton>
        </Link>
      </Container>
    </Wrapper>
  )
}

export default YourMiniApp
