import React, { FC, useMemo } from 'react'

import UpOutlined from '@ant-design/icons/UpOutlined'
import { Card, DatePicker, Row, Dropdown, List, Badge, Spin, MenuProps, Col } from 'antd'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import { MiniappLogKeys } from 'constants/commonValues'
import { DateFormat_MMDDYYYY } from 'constants/dateFormats'

import { GroupedDateStatistic, MiniappDefinition } from '../../model'

import { useDateRangeStatistic } from './useDateRangeStatistic'

const { RangePicker } = DatePicker

const DateRangeStatistic: FC = () => {
  const {
    filterCondition,
    onChangeDate,
    data,
    LogColors,
    onChangeMiniapp,
    isLoading,
    isRefetching,
    miniapps,
    isLoadingMiniappData,
    getColorMiniappById,
    getNameMiniappById,
  } = useDateRangeStatistic()

  const menuProps = useMemo(() => {
    const handleMenuClick: MenuProps['onClick'] = (e) => {
      const selectedMna =
        (miniapps?.data?.data || []).find((mna) => mna?.miniapid === e?.key) || null
      onChangeMiniapp(selectedMna)
    }
    const items: MenuProps['items'] = [
      {
        label: ' All Mininapp',
        key: 'null',
      },
      ...(miniapps?.data?.data || []).map((mna: MiniappDefinition) => ({
        label: mna?.name || mna?.miniappname,
        key: mna?.miniapid,
      })),
    ]

    return {
      items,
      onClick: handleMenuClick,
    }
  }, [miniapps?.data?.data, onChangeMiniapp])

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { payload: hoverData }: { payload: GroupedDateStatistic } = payload?.at(0) || {}
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const coverUi = useMemo(
        () => (
          <List
            bordered
            dataSource={hoverData.data}
            renderItem={(item) => (
              <Badge.Ribbon
                text={getNameMiniappById(item?.miniappid)}
                color={getColorMiniappById(item?.miniappid)}
              >
                <List.Item>
                  <List.Item.Meta
                    title={`total: ${item.error + item.info + item.warn}`}
                    description={`info:${item.info} - warn:${item.warn} - error:${item.error}`}
                  />
                </List.Item>
              </Badge.Ribbon>
            )}
          />
        ),
        [hoverData.data],
      )

      return (
        <Card hoverable style={{ width: 240 }} cover={coverUi}>
          <Card.Meta
            title={hoverData?.date}
            description={`total: ${hoverData.info + hoverData.error + hoverData.warn} logs`}
          />
        </Card>
      )
    }

    return null
  }

  const selectedMnaName = useMemo(() => {
    const selectedMna = (miniapps?.data?.data || []).find(
      (mna: MiniappDefinition) => mna?.miniapid === filterCondition.miniappid,
    )
    return selectedMna?.name || selectedMna?.miniappname || 'All Mininapp'
  }, [filterCondition.miniappid, miniapps?.data?.data])

  return (
    <Spin spinning={isLoading || isRefetching}>
      <Card style={{ marginLeft: 12, marginRight: 12, marginTop: 0 }}>
        <Row justify="space-around">
          <Col>
            <RangePicker
              disabled={isLoading || isRefetching}
              allowClear={false}
              allowEmpty={[false, false]}
              format={DateFormat_MMDDYYYY}
              value={filterCondition.filterDate}
              onChange={onChangeDate}
            />
          </Col>
          <Col>
            <Dropdown.Button
              menu={menuProps}
              placement="topRight"
              arrow={{ pointAtCenter: true }}
              icon={<UpOutlined />}
              loading={isLoading || isRefetching || isLoadingMiniappData}
            >
              {selectedMnaName}
            </Dropdown.Button>
          </Col>
        </Row>
        <Row>
          <ResponsiveContainer minWidth={500} height={400}>
            <LineChart
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 50,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                type="monotone"
                dataKey="info"
                stroke={LogColors[MiniappLogKeys.indexOf('info')]}
              />
              <Line
                type="monotone"
                dataKey="warn"
                stroke={LogColors[MiniappLogKeys.indexOf('warn')]}
              />
              <Line
                type="monotone"
                dataKey="error"
                stroke={LogColors[MiniappLogKeys.indexOf('error')]}
              />
            </LineChart>
          </ResponsiveContainer>
        </Row>
      </Card>
    </Spin>
  )
}

export default DateRangeStatistic
