/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import Buffer from 'buffer'

// import { Modal } from 'antd'
import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'

const BASE_URL = 'https://api.mservice.com.vn:8001/forwarder'
const URL_PROXY = 'http://10.40.114.124/'
const requestTimeout = 30 * 1000

const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type HttpHeaders = {
  [key: string]: string
}

export type RequestConfig = {
  headers: HttpHeaders
}

export type RefreshTokenRequest = {
  refreshToken: string
}

export type RefreshTokenReponse = {
  token: string
  refreshToken: string
  statuscode?: number
  status?: string
}

const convertPayload = (path: string, payload?: any, method: Method = Method.GET) => {
  if (method === Method.GET) {
    const { params } = payload || {
      params: {},
    }
    let urlParams = ''
    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === undefined) {
          urlParams = urlParams.slice(0, -1)
          return
        }

        if (Object.keys(params).length - 1 === Number(key)) {
          urlParams += `${key}=${params[key]}`
          return
        }

        urlParams += `${key}=${params[key]}&`
      })
    }
    const urlConvert = urlParams ? `${URL_PROXY + path}?${urlParams}` : URL_PROXY + path
    const urlBuffer = Buffer.Buffer.from(urlConvert).toString('base64')
    const payloadData = {
      url: urlBuffer,
      method,
      encode: true,
    }
    return payloadData
  }

  const urlConvert = URL_PROXY + path
  const urlBuffer = Buffer.Buffer.from(urlConvert).toString('base64')

  const payloadData = {
    url: urlBuffer,
    method,
    body: payload || {},
    encode: true,
  }

  return payloadData
}

const handleServiceError = (apiError: unknown) =>
  // console.error(apiError)
  Promise.reject(apiError)

export const post = async <TRequest, TResponse>(
  path: string,
  payload?: TRequest,
  config?: RequestConfig,
): Promise<TResponse> => {
  try {
    const payloadData = convertPayload(path, payload, Method.POST)
    const response = await axiosInstance.post<TResponse>('/', payloadData, config)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

export const postWithOrtherDomain = async <TRequest, TResponse>(
  path: string,
  payload?: TRequest,
  config?: RequestConfig,
  domain?: string,
): Promise<TResponse> => {
  try {
    const response = await axiosInstance.post<TResponse>(domain || '/', payload, config)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

export const get = async <TResponse>(path: string, params?: any): Promise<TResponse> => {
  try {
    const payloadData = convertPayload(path, { params }, Method.GET)
    const response = await axiosInstance.post<TResponse>('/', payloadData)
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    // if (response?.data?.status !== 'success') throw (response);
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

export const put = async <TRequest, TResponse>(
  path: string,
  payload?: TRequest,
): Promise<TResponse> => {
  try {
    const payloadData = convertPayload(path, payload, Method.PUT)
    const response = await axiosInstance.post<TResponse>('', payloadData)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

export const patch = async <TRequest, TResponse>(
  path: string,
  payload?: TRequest,
): Promise<TResponse> => {
  try {
    const payloadData = convertPayload(path, payload, Method.PATCH)
    const response = await axiosInstance.post<TResponse>('', payloadData)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

export const del = async <TResponse>(path: string, payload?: any): Promise<TResponse> => {
  try {
    const payloadData = convertPayload(path, payload, Method.DELETE)
    const response = await axiosInstance.post<TResponse>('/', payloadData)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

interface AxiosConfig {
  baseURL?: string
  timeout?: number
  headers?: any
}

axiosInstance.interceptors.request.use(
  async (config: AxiosConfig) => {
    const token = localStorage.getItem('token')
    const instanceConfig = config
    instanceConfig.headers = {
      responseType: 'json' as const,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    }
    instanceConfig.timeout = requestTimeout
    return instanceConfig
  },
  (error) => {
    Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(async (response: AxiosResponse) => {
  const { data } = response || {}

  const url = JSON.parse(response?.config?.data || `{}`)?.url || ''

  if (!isEmpty(url)) {
    const urlDecode = Buffer.Buffer.from(url, 'base64').toString('ascii')
    const refreshToken = localStorage.getItem('refreshToken')

    if (urlDecode.includes('refresh') && !isEmpty(refreshToken)) {
      if (data?.statuscode) {
        if (data.statuscode === 200) {
          return response
        }
        if (data.statuscode === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          window.location.href = '/login'
        }
      }
    }
  }

  if (data?.statuscode) {
    if (['TokenExpiredError', 'JsonWebTokenError'].includes(data?.error?.name)) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('tokenM4B')
      localStorage.removeItem('user')
      window.location.href = '/login'
    }

    if (data.statuscode === 200) {
      return response
    }
    if (data.statuscode === 401) {
      const refreshToken = localStorage.getItem('refreshToken')

      if (refreshToken) {
        const {
          statuscode,
          token,
          refreshToken: newRefreshToken,
        } = await post<RefreshTokenRequest, RefreshTokenReponse>('refresh', {
          refreshToken,
        })

        if (statuscode === 200) {
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', newRefreshToken)
          return response
        }

        if (statuscode === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('tokenM4B')
        }
      }
      // axios.CancelToken.source().cancel()
      // Modal.error({
      //   title: 'Thông báo',
      //   content: 'Phiên đăng nhập hết hạn. Vui lòng đăng nhập lại',
      //   onOk: () => {
      //     localStorage.removeItem('token')
      //     localStorage.removeItem('refreshToken')
      //     localStorage.removeItem('tokenM4B')
      //     window.location.href = '/login'
      //   },
      // });
    }
  }

  return response
})

export default axiosInstance
