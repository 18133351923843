import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Card, Col, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import SearchInput from 'components/SearchInput'

import ActionRole from './components/ActionRole'
import RoleInfoDrawer from './components/RoleInfoDrawer/RoleInfoDrawer'
import { Role } from './model'
import useRLRoleManagement, { EActionType } from './useRLRoleManagement'

const PageContainer = styled.div`
  margin: 12px;
  width: 100%;
`

const RemoteLoggerRoleManagement: FC = () => {
  const {
    handleSearchInput,
    handleTableChange,
    isLoading,
    tableParams,
    data,
    handleOpenRoleDrawer,
    selectedRoleId,
    handleCloseDrawer,
    openDrawer,
    isRefetching,
  } = useRLRoleManagement()

  const columns: ColumnsType<Role> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Action',
      key: 'operation',
      dataIndex: 'roleid',
      fixed: 'right',
      width: 100,
      render: (roleId: string) => (
        <ActionRole
          // onDisable={handleOpenRoleDrawer(roleId, EActionType.disable)}
          onView={handleOpenRoleDrawer(roleId, EActionType.view)}
          onEdit={handleOpenRoleDrawer(roleId, EActionType.edit)}
        />
      ),
    },
  ]

  return (
    <PageContainer>
      <Card style={{ borderRadius: 8 }}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 10 }}>
          <Row justify="space-between">
            <Col>
              <SearchInput onSearch={handleSearchInput} />
            </Col>
            <Col>
              {/* <Button
                type="primary"
                icon={<AppstoreAddOutlined size={30} />}
                size="middle"
                onClick={handleOpenRoleDrawer()}
              >
                New role
              </Button> */}
            </Col>
          </Row>
        </Space>
        <Table
          columns={columns}
          rowKey={(record) => record.roleid}
          dataSource={data?.data || []}
          pagination={tableParams.pagination}
          loading={isLoading || isRefetching}
          onChange={handleTableChange}
        />
      </Card>
      <RoleInfoDrawer roleId={selectedRoleId} onClose={handleCloseDrawer} open={openDrawer} />
    </PageContainer>
  )
}

const remoteLoggerRoleManagementRouter = 'role-mnt'

export default RemoteLoggerRoleManagement
export { remoteLoggerRoleManagementRouter }
