import React, { FC, useMemo } from 'react'

import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined'
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined'
import PieChartOutlined from '@ant-design/icons/PieChartOutlined'
import TableOutlined from '@ant-design/icons/TableOutlined'
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined'
import WifiOutlined from '@ant-design/icons/WifiOutlined'
import styled from '@emotion/styled'
import { Menu, Modal } from 'antd'
import { SignOut } from 'phosphor-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuthContext } from 'contexts/AuthContext'
import isTemplateAdmin from 'utils/isTemplateAdmin'
import { parseJwt, JWTToken } from 'utils/jwt'

import { m4bUserDetailRouter } from '../modules/M4bUserDetail/M4bUserDetail'
import { networkLoggerRouter } from '../modules/NetworkLogger'
import { remoteLoggerRoleManagementRouter } from '../modules/RemoteLoggerRole/RemoteLoggerRoleManagement'
import { remoteLoggerStatisticRouter } from '../modules/RemoteLoggerStatistic'
import { remoteLoggerTableRouter } from '../modules/RemoteLoggerTable'
import { remoteLoggerUserManagementRouter } from '../modules/RemoteLoggerUser'
import { remoteLoggerRouter } from '../RemoteLogger'

interface MainMenuProps {
  className?: string
}

const StyledMenu = styled(Menu)({
  // height: 'calc(100vh - 64px)',
  backgroundColor: '#971D62',
  '.ant-menu-item': {
    paddingInline: 'calc(50% - 18px)',
    color: '#fff !important',
    height: '50px',
    lineHeight: '50px',
    marginInline: '6px',
    marginBlock: '6px',
    width: 'calc(100% - 12px)',
  },
  '.ant-menu-item-selected, .ant-menu-item-active': {
    backgroundColor: '#C73E89 !important',
  },
  '.ant-menu-item-icon': {
    verticalAlign: '-0.3em !important',
  },
})

const MainMenu: FC<MainMenuProps> = ({ className }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const selectedKeys = pathname.split('/').slice(3)
  const { logout } = useAuthContext()
  const menuItem = useMemo(() => {
    const decodedToken = parseJwt<JWTToken>(localStorage.getItem('token') || '')
    // const decodedToken = parseJwt<JWTToken>(cacheUtil.get(CacheKey.loginInfo)?.token || '')

    const handleLogout = () => {
      Modal.confirm({
        title: 'Are you sure you want to logout?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          logout?.()
          navigate('/login')
        },
      })
    }

    const isM4bUser = decodedToken?.user?.isM4B
    if (isM4bUser) {
      return [
        {
          label: 'User information',
          key: 'm4b-user',
          onClick: () => navigate(`../${remoteLoggerRouter}/${m4bUserDetailRouter}`),
          icon: <UserSwitchOutlined />,
        },
        {
          key: 'logout',
          label: 'Logout',
          icon: <SignOut size={24} />,
          onClick: handleLogout,
        },
      ]
    }

    const isAdminUser = isTemplateAdmin()
    const menu = [
      {
        label: 'Overview',
        key: 'overview',
        onClick: () => navigate(`../${remoteLoggerRouter}`),
        icon: <AppstoreOutlined />,
      },
      {
        label: 'Logs',
        key: 'logs',
        onClick: () => navigate(`../${remoteLoggerRouter}/${remoteLoggerTableRouter}`),
        icon: <TableOutlined />,
      },
      {
        label: 'Network Logs',
        key: 'network-statistic',
        onClick: () => navigate(`../${remoteLoggerRouter}/${networkLoggerRouter}`),
        icon: <WifiOutlined />,
      },
      {
        label: 'Statistic',
        key: 'statistic',
        onClick: () => navigate(`../${remoteLoggerRouter}/${remoteLoggerStatisticRouter}`),
        icon: <PieChartOutlined />,
      },
      ...(isAdminUser
        ? [
            {
              label: 'User management',
              key: 'user-management',
              onClick: () =>
                navigate(`../${remoteLoggerRouter}/${remoteLoggerUserManagementRouter}`),
              icon: <UserSwitchOutlined />,
            },
            {
              label: 'Role management',
              key: 'role-management',
              onClick: () =>
                navigate(`../${remoteLoggerRouter}/${remoteLoggerRoleManagementRouter}`),

              icon: <FileDoneOutlined />,
            },
          ]
        : []),
      {
        key: 'logout',
        label: 'Logout',
        icon: <SignOut size={24} />,
        onClick: handleLogout,
      },
    ]
    return menu
  }, [logout, navigate])

  return (
    <StyledMenu
      inlineCollapsed
      className={className}
      selectedKeys={selectedKeys?.length ? selectedKeys : ['']}
      items={menuItem}
    />
  )
}

export default MainMenu
