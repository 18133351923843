import React, { useContext, useEffect } from 'react'

import { message } from 'antd'
import firebase from 'firebase'
import JSEncrypt from 'jsencrypt'
import { useMutation } from 'react-query'

import { auth } from 'fireb'
import { postWithOrtherDomain } from 'utils/Api.miniservice'
import isTemplateAdmin from 'utils/isTemplateAdmin'
import useLocalStorage from 'utils/useLocalStorage'

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAydSrKGi5wI/gmgrS8eJg
2J4zjZBPFGLelrOdzM/dOZisKRSfM/4L9XWTt5dZy2bW7ounrWO2l22HOCQO7dUf
e3pttEv7YHyeTIzEy/goqrKcjmiBJxMeHhuurf11wOYFgIty/nTKVQqcmns4SdYo
T8b3Mubc8zn9zKjtdIU3e1AlXw5VrQCPtcD8iOruM+QPfvM1qNURfJZde9dVd6da
/a2t0tdaYkiYWcjV89FPHlnkfDUj+HoxBptlO4WPCcPJoYfRFX6g1qIG0Ug9891c
3W67Lk6peoDCUuRizVUxbxGYO2tj1gsPJaKtWIq7GStVAm5aoHveUCdOFmucwiMw
swIDAQAB
-----END PUBLIC KEY-----
`

export interface User {
  id: string
  username: string
  miniapps: string[]
  roleNames: string[]
  firebaseUser?: Pick<firebase.User, 'uid' | 'displayName' | 'photoURL' | 'email'> | null
  accessMimo?: boolean
}

export interface LoginRequest {
  username: string
  password: string
}

export interface LoginRequestWithM4b {
  username: string
  password: string
  url: string
}

export interface LoginResponse {
  message: string
  refreshToken: string
  statuscode: number
  token: string
  username: string
  fireBaseToken: string
  tokenM4b?: {
    access_token: string
    refresh_token: string
  }
}

export interface LoginM4BResponse {
  status: number
  message: string
  data: Data
}

export interface Data {
  username: string
  token: string
  fireBaseToken: string
  roles: Roles
}

export interface Roles {
  id: null
  username: null
  phoneNumber: null
  roles: null
  status: null
  type: null
  merchants: Merchant[]
  stores: Store[]
}

export interface Merchant {
  roleName: string
  merchantIds: number[]
}

export interface Store {
  roleName: string
  storeIds: number[]
}

// interface TokenPayload {
//   exp: number
//   iat: number
//   user: {
//     id: string
//     isM4B: boolean
//     miniApps: string[]
//     roleNames: string[]
//     username: string
//   }
// }

interface TokenPayloadM4B {
  exp: number
  iat: number
  type: string
  username: string
}

export interface AuthContextProps {
  user: User | null | undefined
  setUser: (user: User | null) => void
  login: ({ username, password }: { username: string; password: string }) => Promise<void>
  logout?: (option?: { hiddenSuccessMessage: boolean }) => void
  authenticating?: boolean
  isLoggedIn?: boolean | null
}

export const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  setUser: () => {},
  login: async () => {},
  logout: () => {},
  authenticating: false,
  isLoggedIn: null,
})

const hostM4b = `${process.env.REACT_APP_M4B_API_URL || ''}/authentication/mini-app/login`

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage<User | null>({
    key: 'user',
    defaultValue: null,
  })
  const [authenticating, setAuthenticating] = React.useState(false)
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>()

  // const postLogin = async ({ username, password }: LoginRequest) =>
  //   post<LoginRequest, LoginResponse>('login', { username, password })

  const postLoginM4b = async ({ username, password, url }: LoginRequestWithM4b) =>
    postWithOrtherDomain<LoginRequestWithM4b, LoginM4BResponse>(
      '',
      { username, password, url },
      undefined,
      process.env.REACT_APP_M4B_AUTH,
    )

  // const loginMutation = useMutation<LoginResponse, null, LoginRequest>(postLogin, {
  //   mutationKey: 'login',
  // })

  const loginMutationM4b = useMutation<LoginM4BResponse, null, LoginRequestWithM4b>(postLoginM4b, {
    mutationKey: 'loginM4b',
  })

  const login = async ({ username, password }: LoginRequest) => {
    try {
      setAuthenticating(true)
      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(publicKey)
      const encryptedPassword = jsencrypt.encrypt(password)

      const dataM4b = await loginMutationM4b.mutateAsync({
        username,
        password: `${encryptedPassword}`,
        url: hostM4b,
      })

      if (dataM4b?.data?.fireBaseToken) {
        const fireBaseToken = dataM4b.data.fireBaseToken || ''

        localStorage.setItem('fireBaseToken', fireBaseToken)
        if (!fireBaseToken) return
        let firebaseUser
        const firebaseAuthResult = await auth.signInWithCustomToken(fireBaseToken)
        if (firebaseAuthResult?.user) {
          firebaseUser = {
            uid: firebaseAuthResult.user.uid,
            displayName: firebaseAuthResult.user.displayName,
            photoURL: firebaseAuthResult.user.photoURL,
            email: firebaseAuthResult.user.email,
          }
        }

        if (firebaseUser) {
          const tokenDecode: TokenPayloadM4B = JSON.parse(
            window.atob(dataM4b.data.token.split('.')[1]),
          )

          setUser({
            id: tokenDecode.username,
            username: tokenDecode.username,
            miniapps: [],
            roleNames: [],
            firebaseUser,
          })

          setIsLoggedIn(true)
          message.success(`Welcome back, ${tokenDecode.username}!`)
        }

        return
      }

      // const data = await loginMutation.mutateAsync({ username, password })

      // let firebaseUser
      // if (data?.token) {
      //   localStorage.setItem('token', data.token)
      //   if (data.refreshToken) {
      //     localStorage.setItem('refreshToken', data.refreshToken)
      //   }
      //   if (data.tokenM4b?.access_token) {
      //     localStorage.setItem('tokenM4B', data.tokenM4b.access_token)
      //   }
      //   if (data.fireBaseToken) {
      //     try {
      //       localStorage.setItem('fireBaseToken', data.fireBaseToken)
      //       const firebaseAuthResult = await auth.signInWithCustomToken(data.fireBaseToken)
      //       if (firebaseAuthResult?.user) {
      //         firebaseUser = {
      //           uid: firebaseAuthResult.user.uid,
      //           displayName: firebaseAuthResult.user.displayName,
      //           photoURL: firebaseAuthResult.user.photoURL,
      //           email: firebaseAuthResult.user.email,
      //         }
      //       }
      //     } catch (error) {
      //       console.log('💔', error)
      //     }
      //   }
      //   const tokenDecode: TokenPayload = JSON.parse(window.atob(data.token.split('.')[1]))

      //   setUser({
      //     id: tokenDecode.user.id,
      //     username: tokenDecode.user.username,
      //     miniapps: tokenDecode.user.miniApps,
      //     roleNames: tokenDecode.user.roleNames,
      //     firebaseUser,
      //   })

      //   setIsLoggedIn(true)
      //   message.success(`Welcome back, ${data.username || tokenDecode.user.username}!`)
      // } else {
      message.error('An error occurred, please try again!')
      // }

      setAuthenticating(false)
    } catch (error) {
      setAuthenticating(false)

      message.error('An error occurred, please try again!')
    }
  }

  const logout = (options?: { hiddenSuccessMessage?: boolean }) => {
    const { hiddenSuccessMessage } = options || {}
    try {
      auth.signOut()
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('tokenM4B')
      localStorage.removeItem('fireBaseToken')
      setUser(null)
      if (!hiddenSuccessMessage) message.success(`You have been logged out!`)
    } catch (error: any) {
      message.error(error?.message || 'An error occurred, please try again!')
    }
    setUser(null)
    setIsLoggedIn(false)
  }

  useEffect(() => {
    const syncUser = () => {
      auth.onAuthStateChanged(async (userData) => {
        if (!userData) {
          setIsLoggedIn(false)
          return
        }
        setIsLoggedIn(true)
        const newFirebaseToken = await userData?.getIdToken()
        if (newFirebaseToken) {
          localStorage.setItem('fireBaseToken', newFirebaseToken || '')
        } else {
          if (isTemplateAdmin()) {
            return
          }
          setIsLoggedIn(false)
          logout({ hiddenSuccessMessage: true })
        }
      })
    }
    syncUser?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, authenticating, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export const getIdToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser?.getIdToken(/* forceRefresh */ false)
      .then((tokenId) => {
        resolve(tokenId)
      })
      .catch((error) => {
        reject(error.message)
      })
  })
