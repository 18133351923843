import { ThemeConfig } from 'antd/es/config-provider/context'

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#C73E89',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  components: {
    Layout: {
      colorBgHeader: '#971D62',
    },
  },
}

export { default as ThemeProvider } from './ThemeProvider'

export default theme
