import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Spin, Input, Select, Form, Layout, Card } from 'antd'

import { useM4bUserDetail } from '.'

const CardContainer = styled(Card)`
  margin: 12px;
`

const M4bUserDetail: FC = () => {
  const { data, isLoading, form, roles, isLoadingRole } = useM4bUserDetail()
  return (
    <Layout.Content>
      <CardContainer title={`User: ${data?.data?.displayName}`}>
        <Spin spinning={isLoading}>
          <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal">
            <Form.Item
              label="username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Display Name" name="displayName">
              <Input readOnly />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Partner Code" name="partnerCode">
              <Input readOnly />
            </Form.Item>
            <Form.Item
              label="Role"
              name="roleids"
              rules={[{ required: true, message: 'Please choose your role!' }]}
            >
              <Select
                loading={isLoadingRole}
                mode="multiple"
                style={{
                  pointerEvents: 'none',
                }}
                options={roles?.data?.map((role) => ({
                  value: role?.roleid,
                  label: role?.name,
                }))}
              />
            </Form.Item>
          </Form>
        </Spin>
      </CardContainer>
    </Layout.Content>
  )
}
export default M4bUserDetail
export const m4bUserDetailRouter = 'm4b-user'
