/* eslint-disable @typescript-eslint/no-explicit-any */
function isJson(value: any) {
  let suspect = typeof value !== 'string' ? JSON.stringify(value) : value

  try {
    suspect = JSON.parse(suspect)
  } catch (e) {
    return false
  }

  if (typeof suspect === 'object' && suspect !== null) {
    return true
  }

  return false
}
const safeJsonParse = (jsonString?: any) =>
  jsonString && isJson(jsonString) ? JSON.parse(jsonString) : undefined;


export default isJson;
export {
  safeJsonParse
}