/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'

import { MiniAppConfig } from '@momo-miniapp/template'
import { message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { db } from 'fireb'
import useDebouncedEffect from 'hooks/useDebouncedEffect'
import uploadConfigToCdn, { downloadConfigFromCdn } from 'utils/uploadConfigToCdn'

import { useAppContext } from './AppContext'
import { useAuthContext } from './AuthContext'

interface AppSyncContextValues {
  lastSynced?: Dayjs
  syncing: boolean
  syncNow: () => Promise<void>
  deleteProjectFromCloud: (appId: string) => Promise<void>
  syncFromCloud: () => Promise<void>
  syncingFromCloud: boolean
}

const AppSyncContext = React.createContext<AppSyncContextValues>({
  syncing: false,
  syncNow: () => Promise.resolve(),
  deleteProjectFromCloud: () => Promise.resolve(),
  syncFromCloud: () => Promise.resolve(),
  syncingFromCloud: false,
})

export const AppSyncProvider = ({ children }: { children: React.ReactNode }) => {
  const [lastSynced, setLastSynced] = useState<Dayjs>()
  const [syncing, setSyncing] = useState<boolean>(false)
  const [syncingFromCloud, setSyncFromCloud] = useState<boolean>(false)
  const { configs, setConfigs } = useAppContext()
  const { user } = useAuthContext()

  const syncFromCloud = useCallback(async () => {
    if (!user?.id) {
      // remove all non-sync configs
      setConfigs((curConfigs) => {
        const nonSyncedConfigs = (curConfigs || []).filter((config) => !config.configUrl)
        return nonSyncedConfigs
      })
      setSyncFromCloud(false)
      return
    }
    console.log('⛈️ syncing projects from Firestore...')
    setSyncing(true)

    db.collection('app-center-mat-sync')
      // .doc(user.id)
      // .collection('projects')
      .where('members', 'array-contains', user.id)
      .get()
      .then(async (snapshot) => {
        const projects = snapshot.docs.map((doc) => doc.data())
        const cdnConfigs = await Promise.all(
          projects.map((project) => downloadConfigFromCdn(project.configUrl)),
        )
        const remoteConfigs = projects.map((project, index) => {
          const config = {
            ...project,
            ...(cdnConfigs[index] || {}),
          }
          config.connected = project.connected
          delete (config as any).members
          return config
        }) as MiniAppConfig[]

        setConfigs((currentConfigs) => {
          /**
           * Merge localConfigs with remoteConfigs
           * If two configs have the same appId, compare timestamp and keep the latest one
           */
          const newConfigs = [...currentConfigs, ...remoteConfigs].reduce(
            (acc: MiniAppConfig[], config) => {
              const existingConfig = acc.find((c) => c.appId === config.appId)
              if (existingConfig) {
                if ((config.timestamp || 0) >= (existingConfig.timestamp || 0)) {
                  acc[acc.indexOf(existingConfig)] = config
                }
              } else {
                acc.push(config)
              }
              return acc
            },
            [],
          )

          return newConfigs
        })

        /**
         * TODO: compare and update local configs
         */

        setSyncing(false)
        setLastSynced(dayjs())
        setSyncFromCloud(false)
      })
      .catch((error) => {
        console.log('🔽 error', error)
        setSyncing(false)
      })
  }, [setConfigs, user])

  useEffect(() => {
    /**
     * Fetch projects from Firestore, parse full config from Storage
     * compare and update local configs
     */
    // setSyncFromCloud(true)
    // syncFromCloud()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  const startSync = useCallback(async () => {
    if (!user?.id || !configs) return

    setSyncing(true)

    try {
      await Promise.all(
        configs.map(async (config) => {
          const cdnResult = await uploadConfigToCdn(
            `mat-configs-${user?.id}-${config.appId?.replace('minitemplate.fnb.', '')}.json`,
            config,
          )
          const project = {
            id: config.id,
            appId: config.appId,
            miniAppName: config.miniAppName,
            appLogo: config.appLogo,
            appType: config.appType,
            configUrl: cdnResult?.url,
            timestamp: config.timestamp || 0,
          }

          const projectData = (
            await db.collection('app-center-mat-sync').doc(config.id).get()
          ).data()
          if (
            projectData &&
            projectData.members?.length &&
            !projectData.members?.includes(user.id)
          ) {
            console.log('🙅‍♀️ project access revoked', config.id)
            // Remove from local configs if user is not a member
            setConfigs((currentConfigs) => currentConfigs.filter((c) => c.id !== config.id))
            return
          }

          await db.collection('app-center-mat-sync').doc(config.id).set(project, { merge: true })

          if (!config.configUrl && cdnResult?.url) {
            // Update local config with new configUrl
            setConfigs((currentConfigs) => {
              const newConfigs = [...currentConfigs]
              const index = newConfigs.findIndex((c) => c.id === config.id)
              if (index >= 0) {
                newConfigs[index].configUrl = cdnResult?.url
              }
              return newConfigs
            })
          }

          /**
           * Set project owner in case of new project
           */

          if (!projectData?.members?.length) {
            console.log('👤 set project owner', config.id, user.id)
            const batch = db.batch()
            batch.set(
              db.collection('app-center-mat-sync').doc(config.id),
              { members: [user.id] },
              { merge: true },
            )

            batch.set(
              db
                .collection('app-center-mat-members')
                .doc(config.id)
                .collection('members')
                .doc(user.id),
              { user: user.id, role: 'owner', isActive: true },
            )

            await batch.commit()
            /**
             * Add owner to members collection for authorization
             * and collaborators query
             */
            await db
              .collection('app-center-mat-members')
              .doc(config.id)
              .collection('members')
              .doc(user.id)
              .set({ user: user.id, role: 'owner', isActive: true })
          } else if (projectData?.members?.indexOf(user.id) === -1) {
            console.log('🙅‍♀️ project access revoked', config.id)
            // Remove from local configs if user is not a member
            setConfigs((currentConfigs) => currentConfigs.filter((c) => c.id !== config.id))
            message.error(`Your access to project ${config.miniAppName} has been revoked!`)
          }
        }),
      )

      setSyncing(false)
      setLastSynced(dayjs())
    } catch (error) {
      console.log('🆙 error', error)
      setSyncing(false)
    }
  }, [configs, setConfigs, user])

  useDebouncedEffect(
    () => {
      /**
       * Sync local configs to Firestore and Storage
       */
      // startSync()
    },
    5000,
    [configs, user?.id],
  )

  const deleteProjectFromCloud = useCallback(
    async (id: string) => {
      if (!user?.id) return

      await db.collection('app-center-mat-sync').doc(id).delete()
      await db.collection('app-center-mat-members').doc(id).delete()
      await db.collection('app-center-mat-secret').doc(id).delete()

      /**
       * TODO: Prevent deleting project if it's connected
       * await db.collection('app-center-mat-connect').doc(id).delete()
       */
    },
    [user?.id],
  )

  return (
    <AppSyncContext.Provider
      value={{
        lastSynced,
        syncing,
        syncingFromCloud,
        syncNow: startSync,
        deleteProjectFromCloud,
        syncFromCloud,
      }}
    >
      {children}
    </AppSyncContext.Provider>
  )
}

export const useAppSyncContext = () => React.useContext(AppSyncContext)

export default AppSyncContext
