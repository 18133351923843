export const parseJwt = <T>(token: string) => {
  // const tokenDecode = JSON.parse(window.atob((response.data.token || '').split('.')[1]))

  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  return JSON.parse(jsonPayload) as T;
}

export type JWTToken = {
  user: JWTTokenUser;
  iat: number;
  exp: number;
}

export type JWTTokenUser = {
  id: string;
  username: string;
  role: string[];
  miniapps: string[];
  isM4B: boolean;
  miniApps: string[];
  roleNames: string[];
}
