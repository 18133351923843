/* eslint-disable no-nested-ternary */
import React, { useLayoutEffect, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import { Button, Space, Spin } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAuthContext } from 'contexts/AuthContext'
import { db } from 'fireb'
import { post } from 'utils/Api.macConnectionService'

import { ModalConnect } from './ModalConnect'

interface ConnectResponse<T> {
  code: number
  data: T
  status: string
  message: {
    en_US: string
    vi_VN: string
  }
}

const Wrapper = styled.div({
  backgroundColor: '#fff',
})
const Container = styled.div((props) => ({
  height: 'calc(100vh - 128px)',
  padding: props.theme.padding,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: props.theme.colorBgContainer,
}))

const ContentWrapper = styled.div((props) => ({
  border: `1px solid ${props.theme.colorBorder}`,
  borderRadius: props.theme.borderRadiusLG,
  padding: '32px 48px',
  width: '100%',
  maxWidth: '750px',
}))

const TitleStyle = styled.p(() => ({
  fontSize: '24px',
  fontWeight: 'bold',
  margin: 0,
}))

const DevideLine = styled.div((props) => ({
  width: '100%',
  height: '1px',
  backgroundColor: props.theme.colorBorder,
  margin: '12px 0px 16px 0px',
}))

const DescriptionStyle = styled.p((props) => ({
  fontSize: '16px',
  margin: 0,
  marginBottom: '8px',
  color: props.theme.colorTextSecondary,
}))

const ButtonWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '32px',
}))

const Connect = () => {
  const navigate = useNavigate()
  const { user, isLoggedIn } = useAuthContext()
  const { search, state } = useLocation()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [accessMimo, setAccessMimo] = useState(false)
  const [loading, setLoading] = useState(true)
  const [fakeLoading, setFakeLoading] = useState(false)
  const [invalidSession, setInvalidSession] = useState(false)
  const [miniAppId, setMiniAppId] = useState<string | null>('')

  const session = useMemo(() => new URLSearchParams(search).get('session'), [search])

  useLayoutEffect(() => {
    ;(async () => {
      if (!session) navigate('/dashboard', { replace: true })
      async function getInfoSession() {
        const sessionData = await db
          .collection('app-center-one-time-tokens')
          .doc(session || '')
          .get()
          .then((querySnapshot) => querySnapshot.data())
        return sessionData
      }
      const getDataSession = await getInfoSession()
      const dataSession = state?.dataSession || getDataSession

      if (dataSession) {
        const distanctTime = new Date().getTime() - (dataSession?.createdAt || 0)

        if (user && user?.firebaseUser?.uid !== dataSession?.userId) {
          await db
            .collection('app-center-one-time-tokens')
            .doc(session || '')
            .delete()
          setInvalidSession(true)
          setLoading(false)
        }

        if (distanctTime > 600000) {
          await db
            .collection('app-center-one-time-tokens')
            .doc(session || '')
            .delete()
          setInvalidSession(true)
          setLoading(false)
        } else {
          setMiniAppId(dataSession?.miniAppId)
          await db
            .collection('app-center-one-time-tokens')
            .doc(session || '')
            .delete()
          if (!user && isLoggedIn === false)
            navigate('/login', {
              replace: true,
              state: {
                session,
                dataSession,
              },
            })

          const checkAccessMimo = async () => {
            setLoading(true)
            if (user) {
              const userInfo = await db
                .collection('app-center-developers')
                .where('uid', '==', user?.firebaseUser?.uid)
                .get()
                .then((querySnapshot) => querySnapshot.docs.map((doc) => doc.data()))
              setAccessMimo(userInfo[0]?.accessMimo || false)
            }
            setLoading(false)
          }

          checkAccessMimo?.()
        }
      } else {
        setLoading(false)
        setInvalidSession(true)
      }
    })()
  }, [user, session, isLoggedIn, navigate, state])

  const handleGrantAccess = async () => {
    setFakeLoading(true)
    const resAccess: ConnectResponse<null> = await post('/access-mimo', {
      userId: user?.firebaseUser?.uid,
    })
    if (resAccess?.code === 1) {
      setAccessMimo(true)
      setTimeout(() => {
        setFakeLoading(false)
        setIsModalVisible(true)
      }, 700)
    } else {
      setFakeLoading(false)
    }
  }

  return (
    <Wrapper>
      <Spin spinning={loading}>
        {!invalidSession ? (
          !isModalVisible && !accessMimo ? (
            <Container>
              <ContentWrapper>
                <TitleStyle>Confirm access to your account</TitleStyle>
                <DevideLine />
                <>
                  <DescriptionStyle style={{ marginBottom: '32px' }}>
                    <b>Mini App Center</b> is requesting access to the following:
                  </DescriptionStyle>
                  <DescriptionStyle>
                    Read your workspace&apos;s project settings and read repositories contained
                    within your workspace&apos;s projects
                  </DescriptionStyle>
                  <DescriptionStyle>
                    Read your repositories and their pull requests
                  </DescriptionStyle>
                  <DescriptionStyle>Read your team membership information</DescriptionStyle>
                </>
                <ButtonWrapper>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleGrantAccess()
                    }}
                    loading={fakeLoading}
                  >
                    <Space>Grant access</Space>
                  </Button>
                  <Button
                    type="default"
                    style={{ marginLeft: '16px' }}
                    onClick={() => {
                      window.close()
                    }}
                  >
                    <Space>Cancel</Space>
                  </Button>
                </ButtonWrapper>
              </ContentWrapper>
            </Container>
          ) : (
            <ModalConnect miniAppId={miniAppId} user={user} />
          )
        ) : (
          <Container>
            <ContentWrapper>
              <TitleStyle>Your request to connect is unavailable</TitleStyle>
              <DevideLine />
              <DescriptionStyle>
                Unable to process request due to missing initial state. This may happen if browser
                sessionStorage is inaccessible or accidentally cleared.
              </DescriptionStyle>
              <ButtonWrapper>
                <Button
                  type="primary"
                  onClick={() => {
                    window.close()
                  }}
                  loading={fakeLoading}
                >
                  <Space>Close</Space>
                </Button>
              </ButtonWrapper>
            </ContentWrapper>
          </Container>
        )}
      </Spin>
    </Wrapper>
  )
}

export default Connect
