import { useEffect, useState } from "react";

import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, FilterConfirmProps } from "antd/lib/table/interface";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { get } from "utils/Api.miniservice";

import { GetMiniappsResponse } from "../RemoteLoggerStatistic/model";

import { GetUserResponse, GetUserResquest, TableParams, User } from "./model";


export enum EActionType {
  create = 'CREATE', view = 'VIEW', edit = 'EDIT', disable = 'DISABLE'
}

const useRLUserManagement = () => {
  const navigator = useNavigate();
  const [openDrawer, setDrawer] = useState<EActionType | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const { data: miniapps, isLoading: isLoadingMiniappData } = useQuery(
    ['get-miniapps'],
    () => get<GetMiniappsResponse>('miniapp'),
    {
      cacheTime: 3 * 60 * 1000,
      retry: false
    }
  );

  const fetchData = () => {
    const request: GetUserResquest = {
      page: tableParams.pagination?.current,
      limit: tableParams.pagination?.pageSize,
    }
    return get<GetUserResponse>('user', request);
  };

  const { isLoading, refetch, data, isRefetching } = useQuery(['rl-user-mnt'], fetchData, {
    onSuccess: (resp) => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: resp.data.page,
          total: resp?.data?.totalDocs,
        },
      });
    },
    refetchInterval: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    retry: false
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue> | any,
    sorter: SorterResult<User> | SorterResult<User>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm({ closeDropdown: false });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setTableParams((state) => ({ ...state, filters: {} }));
  };

  const handleSearchInput = (searchTerm: string) => {
    setTableParams((state) => ({ ...state, searchTerm, filters: {} }));
  };

  const handleOpenUserDrawer = (userId?: string, actionType: EActionType = EActionType.create) => () => {
    // eslint-disable-next-line default-case
    switch (actionType) {
      case EActionType.create:
        setDrawer(actionType);
        break;
      case EActionType.edit:
      case EActionType.view:
      case EActionType.disable:
        setSelectedUserId(userId)
        setDrawer(actionType);
    }
  }

  const handleCloseDrawer = () => {
    setDrawer(null); setSelectedUserId('');
  }

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, JSON.stringify(tableParams)]);

  return {
    navigator,
    tableParams,
    setTableParams,
    data,
    isLoading,
    isRefetching,
    refetch,
    handleTableChange,
    handleReset,
    handleSearch,
    handleSearchInput,
    openDrawer,
    setDrawer,
    handleOpenUserDrawer,
    handleCloseDrawer,
    selectedUserId,
    miniapps,
    isLoadingMiniappData
  }
}

export default useRLUserManagement;

