import React, { FC } from 'react'

import { red, green, blue } from '@ant-design/colors'
import { Progress } from 'antd'

interface RequestRateProgressProps {
  requestDuration: number // ms
  showInfo?: boolean
  steps?: number
}

const allowRequestTimeoutLimit = 6 * 1000 // ms

const RequestRateProgress: FC<RequestRateProgressProps> = ({
  requestDuration = 0,
  showInfo = false,
  steps = 5,
}) => {
  const percent = (requestDuration * 100) / allowRequestTimeoutLimit
  return (
    <Progress
      gapDegree={0}
      showInfo={showInfo}
      percent={percent < 10 ? 10 : percent}
      steps={steps}
      strokeColor={[blue[5], blue[3], green[3], green[4], red[5]]}
    />
  )
}

export default RequestRateProgress
