import React, { createContext, useContext, useState } from 'react'

import { AxiosError } from 'axios'

interface RemoteLoggerContextValue {
  changeToken: boolean
  setChangeToken: (data: boolean) => void
  apiError?: AxiosError | null
  setApiError: (data: any) => void
}

export const RemoteLoggerContext = createContext<RemoteLoggerContextValue>({
  changeToken: false,
  setChangeToken: () => {},
  apiError: null,
  setApiError: () => {},
})

export const RemoteLoggerProvider = ({ children }: { children: JSX.Element }) => {
  const [changeToken, setChangeToken] = useState(false)
  const [apiError, setApiError] = useState(null)
  return (
    <RemoteLoggerContext.Provider value={{ changeToken, setChangeToken, apiError, setApiError }}>
      {children}
    </RemoteLoggerContext.Provider>
  )
}

export default function useRemoteLogger() {
  const contextValues = useContext(RemoteLoggerContext)
  return contextValues
}
