/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { Form, message, Modal } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { get, post } from 'utils/Api.miniservice';

// eslint-disable-next-line import/no-cycle
import { RoleInfoDrawerProps } from '..';
import {
  DeleteRoleResponse,
  GetRoleDetailResponse,
  PostRoleRequest,
  PostRoleResponse,
  PutRoleRequest,
  PutRoleResponse,
} from '../../model';
import useRLRoleManagement, { EActionType } from '../../useRLRoleManagement';




type RoleInfoDrawerParams = RoleInfoDrawerProps;

const useRoleInfoDrawer = ({ open, roleId, onClose }: RoleInfoDrawerParams) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { data: datas } = useRLRoleManagement();
  const [form] = Form.useForm();

  const { refetch: refetchRoleData } = useRLRoleManagement();

  const fetchData = async () => {
    if (roleId) {
      const resp: GetRoleDetailResponse | undefined = await get<GetRoleDetailResponse>(
        `role/${roleId}`
      );
      if (resp && !isEmpty(resp.data)) {
        const roleDetail = structuredClone(resp.data);
        roleDetail.created_at = dayjs(roleDetail?.created_at);
        roleDetail.updated_at = dayjs(roleDetail?.updated_at);
        return roleDetail;
      }
    }
    return null;
  };

  const { isLoading, isRefetching, refetch } = useQuery(['role-detail', roleId], fetchData, {
    onSuccess: (data) => {
      form.setFieldsValue(data);
    },
    retry: false
  });

  useEffect(() => {
    form.resetFields();
    switch (open) {
      case EActionType.create:
      case EActionType.view:
      case EActionType.edit:
      case EActionType.disable:
        refetch();
        break;
      default:
        break;
    }
  }, [form, open, refetch, roleId]);

  const handleSubmit = async () => {
    const { name, description } = form.getFieldsValue();
    // eslint-disable-next-line default-case
    switch (open) {
      case EActionType.create:
        await form.validateFields();
        try {
          const request: PostRoleRequest = {
            name,
            description,
          };
          const resp = await post<PostRoleRequest, PostRoleResponse>(`role`, request);
          if (resp.status === 'success') messageApi.success(`Create new role ${name} success`);
          refetchRoleData();
        } catch (error: any) {
          messageApi.error(error.response.data.message);
        }
        break;
      case EActionType.edit:
        await form.validateFields();
        try {
          const request: PutRoleRequest = {
            name,
            description,
          };
          const resp = await post<PutRoleRequest, PutRoleResponse>(
            `role/update/${roleId}`,
            request
          );
          if (resp.status === 'success') messageApi.success(`Update role ${name} success`);
          refetchRoleData();
        } catch (error: any) {
          messageApi.error(error.response.data.message);
        }
        break;
      case EActionType.disable:

        Modal.confirm(
          {
            title: 'Warning',
            content: `Are you sure you want to disable ${name} role?`,
            onOk: async () => {
              try {
                const resp = await post<typeof undefined, DeleteRoleResponse>(`role/delete/${roleId}`);
                if (resp?.status === 'success') messageApi.success(`Delete role ${name} success`);
                refetchRoleData();
              } catch (error: any) {
                messageApi.error(error.response.data.message);
              }
            }
          }
        );

        break;
      case EActionType.view:
        onClose();
        break;
    }
  };

  return {
    datas,
    form,
    isLoading,
    isRefetching,
    handleSubmit,
    contextHolder,
  };
};

export default useRoleInfoDrawer;
