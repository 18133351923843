import { useEffect } from 'react'

type EffectCallback = () => void | (() => void)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebouncedEffect = (effect: EffectCallback, delay: number, deps: ReadonlyArray<any>) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay])
}

export default useDebouncedEffect
