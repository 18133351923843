import React, { useCallback, useState } from 'react'

import styled from '@emotion/styled'
import { MiniAppConfig } from '@momo-miniapp/template'
import { Typography, Row, Col, Radio, Button, message } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppContext } from 'contexts/AppContext'
import { useAppSyncContext } from 'contexts/AppSyncContext'
import { User } from 'contexts/AuthContext'
import { get, post } from 'utils/Api.macConnectionService'
import mq from 'utils/mediaQuery'

import TemplateItem from './TemplateItem'

interface ModalConnectProps {
  miniAppId: string | null
  user: User | null | undefined
}

interface ConnectResponse<T> {
  code: number
  data: T
  status: string
  message: {
    en_US: string
    vi_VN: string
  }
}

const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '450px',
  justifyContent: 'space-between',
  padding: `0px ${props.theme.paddingLG}px`,
  maxWidth: 960,
  margin: '0 auto',
  [mq('sm')]: {
    paddingRight: props.theme.paddingLG,
  },
}))

const Wrapper = styled.div({
  marginBottom: 24,
})

const Title = styled(Typography.Title)({
  textAlign: 'center',
  marginBottom: '38px !important',
  marginTop: '12px !important',
  fontWeight: '500 !important',
})

const SmallTitle = styled(Typography.Title)({
  textAlign: 'center',
  fontSize: '16px !important',
  fontWeight: '400 !important',
})

const RadioGroup = styled(Radio.Group)({
  width: '100%',
})

const RadioButtonWrapper = styled(Radio.Button)({
  width: '100%',
  height: '100%',
  padding: '16px',
  '&:hover': {
    borderColor: '#c73e89',
  },
})

const ButtonWrapper = styled.div({
  marginBottom: '24px',
})

export const ModalConnect = ({ miniAppId, user }: ModalConnectProps) => {
  const { configs } = useAppContext()
  const { syncingFromCloud } = useAppSyncContext()
  const [loadingConnect, setLoadingConnect] = useState(false)
  const { t } = useTranslation()

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()

  const handleConnect = useCallback(async () => {
    if (selectedTemplateId) {
      setLoadingConnect(true)
      let secretKey = ''
      const existKey: ConnectResponse<string> = await get(
        `/get-key?templateId=${selectedTemplateId}`,
      )
      if (existKey?.data) {
        secretKey = existKey.data
      } else {
        const createKey: ConnectResponse<string> = await post('/gen-key', {
          templateId: selectedTemplateId,
        })
        secretKey = createKey.data
      }
      if (!secretKey) {
        message.error('Cannot get secret key')
      }
      const resConnect: ConnectResponse<{
        templateId: string
        miniAppId: string
        connectedBy: string
      }> = await post(
        '/request-connect',
        {
          templateId: selectedTemplateId,
          miniAppId,
          connectedBy: user?.id,
        },
        {
          headers: {
            secretKey,
          },
        },
      )
      if (resConnect.code === 1 && resConnect.data) {
        message.success('Connect successfully')
        setTimeout(() => {
          window.close()
        }, 500)
      }
    }
    return null
  }, [miniAppId, selectedTemplateId, user?.id])

  return (
    <Container>
      <Wrapper>
        <Title level={2}>My mini apps</Title>
        <RadioGroup onChange={(e) => setSelectedTemplateId(e.target.value)}>
          <Row gutter={[24, 24]}>
            {(syncingFromCloud
              ? Array.from(
                  { length: Math.floor(Math.random() * 10) + 1 },
                  () => ({} as MiniAppConfig),
                )
              : configs
            )?.map((config) =>
              !config?.connected ? (
                <Col key={config.appId} lg={8} md={12} sm={12} span={24}>
                  <RadioButtonWrapper value={config.id}>
                    <TemplateItem data={config} loading={syncingFromCloud} />
                  </RadioButtonWrapper>
                </Col>
              ) : null,
            )}
            {configs && configs?.filter((item) => item?.connected)?.length === configs?.length ? (
              <Col key="alreadyConnected" span={24}>
                <SmallTitle>Your Mini Apps have already been connected.</SmallTitle>
              </Col>
            ) : null}
          </Row>
        </RadioGroup>
      </Wrapper>
      {configs && configs?.filter((item) => item?.connected)?.length !== configs?.length ? (
        <ButtonWrapper>
          <Button
            type="primary"
            size="large"
            disabled={!selectedTemplateId}
            block
            onClick={handleConnect}
            loading={loadingConnect}
          >
            Connect
          </Button>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <Button type="primary" size="large" block onClick={() => window.close()}>
            Close
          </Button>
        </ButtonWrapper>
      )}
    </Container>
  )
}
