import React from 'react'

import { ConfigProvider, App as AntdApp } from 'antd'
import AppRouter from 'App.router'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'antd/dist/reset.css'

import theme, { ThemeProvider } from 'common/theme'
import { AppContextProvider } from 'contexts/AppContext'
import { AppSyncProvider } from 'contexts/AppSyncContext'
import { AuthProvider } from 'contexts/AuthContext'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ConfigProvider theme={theme}>
          <AntdApp>
            <ThemeProvider>
              <AppContextProvider>
                <AppSyncProvider>
                  <AppRouter />
                </AppSyncProvider>
              </AppContextProvider>
            </ThemeProvider>
          </AntdApp>
        </ConfigProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
