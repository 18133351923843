const HardAdminCode = 'admin';

const MiniappLogKeys = ['info', 'warn', 'error',];
const LogColors = ['#0088FE', '#FFCC00', '#FF3333'];

const MiniappDefaultColors: {
  [key: string]: string;
} = {
  'miniapp.b8CtopaVkVnPXmr8PsXC.passio': '#AECF10',
  'miniapp.template.passio': '#AECF10',
  'miniapp.template.highlands': '#b3282d',
  'miniapp.R18Dwd2LljOg00Wbt2Cm.highlandscoffee': '#b3282d',
}


export {
  MiniappLogKeys, LogColors, MiniappDefaultColors, HardAdminCode
}