import React, { FC } from 'react'

import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined'
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined'
import { Tag } from 'antd'

interface CustomTagProps {
  level?: ELevelTag
}

enum ELevelTag {
  info = 'info',
  warn = 'warn',
  error = 'error',
}

const CustomLevelTag: FC<CustomTagProps> = ({ level }) => {
  if (!level) return null
  switch (level) {
    case ELevelTag.error:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error" style={{ minWidth: 70 }}>
          {level}
        </Tag>
      )
    case ELevelTag.warn:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning" style={{ minWidth: 70 }}>
          {level}
        </Tag>
      )
    case ELevelTag.info:
    default:
      return (
        <Tag icon={<InfoCircleOutlined />} color="blue" style={{ minWidth: 70 }}>
          {level}
        </Tag>
      )
  }
}

export default CustomLevelTag
export { ELevelTag }
export type { CustomTagProps }
