import { Form } from 'antd';
import { useQuery } from "react-query";

import { get } from 'utils/Api.miniservice';
import { parseJwt, JWTToken } from 'utils/jwt';

import { GetRoleResponse } from '../RemoteLoggerRole/model';

import { GetM4BUserResponse } from "./model";


const useM4bUserDetail = () => {
  const [form] = Form.useForm();
  // const userLogin = cacheUtil.get(CacheKey.loginInfo);
  const userFromToken = parseJwt<JWTToken>(localStorage.getItem('token') || '')?.user;
  const fetchData = () => userFromToken?.isM4B ? get<GetM4BUserResponse>(`m4b/${userFromToken.username}`) : Promise.reject()

  const { isLoading, data } = useQuery([userFromToken.username], fetchData, {
    onSuccess: (resp) => {
      form.setFieldsValue(resp?.data);
    },
    retry: false
  });

  const { data: roles, isLoading: isLoadingRole } = useQuery(
    ['get-role-select'],
    () => get<GetRoleResponse>('role'),
    {
      cacheTime: 3 * 60 * 1000,
    }
  );

  return {
    data,
    isLoading,
    form,
    roles,
    isLoadingRole
  }
}
export default useM4bUserDetail;