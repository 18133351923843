import { useTheme } from '@momo-miniapp/template'
import Form from 'antd/lib/form'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { get } from 'utils/Api.miniservice'

import { ELevelTag } from './components'

interface RemoteLoggerDetailResponse {
  title: string
  data: {
    apiname?: string
    createdAt?: Date
    level?: ELevelTag
    miniappid?: string
    miniappname?: string
    msg?: string
    publicid: string
    stacktrace?: string
    _id?: string
  }
}

const useRemoteLoggerDetail = () => {
  const [form] = Form.useForm()

  const navigator = useNavigate()
  const { idLog } = useParams()

  const { palette } = useTheme()

  const getLogger = async () => {
    if (!idLog) return null
    const resp = await get<RemoteLoggerDetailResponse>(`loggers/${idLog}`)
    return resp
  }

  const { data, isLoading } = useQuery([`logger-detail/${idLog}`], getLogger, {
    cacheTime: 10 * 1000,
    // onError: (error) => {
    //   _remoteLoggerCtx.setApiError(error);
    // },
    retry: false,
  })

  return {
    getLogger,
    navigator,
    palette,
    form,
    idLog,
    data,
    isLoading,
  }
}

export default useRemoteLoggerDetail
