import React, { createContext } from 'react'

import { MiniAppConfig } from '@momo-miniapp/template'

import useUndoableState from 'utils/useUndoableState'

const AppContext = createContext<{
  configs?: MiniAppConfig[]
  setConfigs: (configs: MiniAppConfig[] | ((prevState: MiniAppConfig[]) => MiniAppConfig[])) => void
  store: {
    undo: () => void
    redo: () => void
    undoable: boolean
    redoable: boolean
    wipeHistory: () => void
  }
}>({
  configs: [],
  setConfigs: () => {},
  store: {
    undo: () => {},
    redo: () => {},
    wipeHistory: () => {},
    undoable: false,
    redoable: false,
  },
})

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    state: configs,
    setState: setConfigs,
    undo,
    redo,
    undoable,
    redoable,
    wipeHistory,
  } = useUndoableState<MiniAppConfig[]>(
    [],
    `@mimo/app-configs-${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  )

  return (
    <AppContext.Provider
      value={{ configs, setConfigs, store: { undo, redo, undoable, redoable, wipeHistory } }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => React.useContext(AppContext)

export default AppContext
