import { useCallback, useEffect, useState } from "react"

import dayjs from 'dayjs';
import { useQuery } from "react-query";

import { get } from "utils/Api.miniservice";

import { GetYearStatisticResponse, MiniappLogFigures } from "../../model";

export const useSystemStatistic = () => {

  const [selectedYear, setSelectedYear] = useState<dayjs.Dayjs>(dayjs());

  const [total, setTotal] = useState<number>(0);
  const [data, setData] = useState<Array<MiniappLogFigures>>();

  const onChangeYear = useCallback((value: dayjs.Dayjs) => {
    setSelectedYear(value);
  }, []);

  const applyFilter = () => {
    const request = {
      dateType: 'year',
      year: selectedYear.year()
    };
    return get<GetYearStatisticResponse>('barchart', request);
  };

  const { isLoading, isFetched, isFetching, isRefetching, refetch } = useQuery(['statistic-year', selectedYear], applyFilter,
    {
      refetchInterval: 40 * 1000,
      onSuccess: (resp) => {
        setData(resp?.data);
        setTotal(resp?.total || 0);
      },
      retry: false
    });

  useEffect(() => {
    refetch()
  }, [refetch, selectedYear]);

  return {
    selectedYear,
    onChangeYear,
    data,
    total,
    isLoading,
    isFetched,
    isFetching,
    isRefetching,
    refetch
  }
}