import React, { FC } from 'react'

import UserAddOutlined from '@ant-design/icons/UserAddOutlined'
import styled from '@emotion/styled'
import { Button, Card, Col, Row, Select, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import SearchInput from 'components/SearchInput'

import { ActionUser } from './components'
import UserInfoDrawer from './components/UserInfoDrawer/UserInfoDrawer'
import { User } from './model'
import useRLUserManagement, { EActionType } from './useRLUserManagement'

const PageContainer = styled.div`
  margin: 12px;
  width: 100%;
`

const RemoteLoggerUserManagement: FC = () => {
  const {
    handleSearchInput,
    handleTableChange,
    isLoading,
    tableParams,
    data,
    handleOpenUserDrawer,
    selectedUserId,
    handleCloseDrawer,
    openDrawer,
    isRefetching,
    miniapps,
    isLoadingMiniappData,
  } = useRLUserManagement()

  const columns: ColumnsType<User> = [
    {
      title: 'User name',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Owner app',
      dataIndex: 'ownerapp',
      render: (ownerapp) => (
        <Select
          loading={isLoadingMiniappData}
          style={{
            pointerEvents: 'none',
          }}
          mode="multiple"
          value={ownerapp}
          options={(miniapps?.data?.data || [])?.map((mna) => ({
            value: mna?.miniapid,
            label: mna?.name,
          }))}
        />
      ),
    },
    {
      title: 'Action',
      key: 'operation',
      dataIndex: 'userid',
      fixed: 'right',
      width: 100,
      render: (userId: string) => (
        <ActionUser
          onDisable={handleOpenUserDrawer(userId, EActionType.disable)}
          onView={handleOpenUserDrawer(userId, EActionType.view)}
          onEdit={handleOpenUserDrawer(userId, EActionType.edit)}
        />
      ),
    },
  ]

  return (
    <PageContainer>
      <Card style={{ borderRadius: 8 }}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 10 }}>
          <Row justify="space-between">
            <Col>
              <SearchInput onSearch={handleSearchInput} />
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<UserAddOutlined size={30} />}
                size="middle"
                onClick={handleOpenUserDrawer()}
              >
                New user
              </Button>
            </Col>
          </Row>
        </Space>
        <Table
          columns={columns}
          rowKey={(record) => record.userid}
          dataSource={data?.data?.docs || []}
          pagination={tableParams.pagination}
          loading={isLoading || isRefetching}
          onChange={handleTableChange}
        />
      </Card>
      <UserInfoDrawer userId={selectedUserId} onClose={handleCloseDrawer} open={openDrawer} />
    </PageContainer>
  )
}

const remoteLoggerUserManagementRouter = 'user-mnt'

export default RemoteLoggerUserManagement
export { remoteLoggerUserManagementRouter }
