import { MiniAppConfig } from '@momo-miniapp/template'
import axios from 'axios'

import { storage } from 'fireb'

type UploadConfigToCdnResult = {
  url: string
  size: number
  name: string
}

const uploadConfigToCdn = async (
  fileName: string,
  config: MiniAppConfig,
): Promise<UploadConfigToCdnResult> => {
  // Transform config object to JSON file
  const file = new File([JSON.stringify(config)], fileName, {
    type: 'application/json',
  })

  return new Promise((resolve, reject) => {
    storage
      .ref(`mat-configs/${fileName}`)
      .put(file)
      .then(async (result) => {
        const url = await storage.ref(result.metadata.fullPath).getDownloadURL()
        resolve({
          url,
          size: result.metadata.size,
          name: result.metadata.name,
        })
      })
      .catch(reject)
  })
}

export const downloadConfigFromCdn = async (url?: string) => {
  if (!url) {
    return null
  }
  try {
    const response = await axios.get(url)
    return response.data as MiniAppConfig
  } catch (error) {
    return null
  }
}

export default uploadConfigToCdn
