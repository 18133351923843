/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import { Drawer, Space, Button, DatePicker, Form, Input, Select, Switch, Spin } from 'antd'

import { EActionType } from '../../useRLUserManagement'

import useUserInfoDrawer from './useUserInfoDrawer'

type UserInfoDrawerProps = {
  userId?: string
  open: EActionType | null
  onClose: () => void
}

const UserInfoDrawer: FC<UserInfoDrawerProps> = ({ open, onClose, userId }) => {
  const {
    form,
    miniapps,
    isLoadingMiniappData,
    roles,
    isLoadingRole,
    handleSubmit,
    contextHolder,
    isLoading,
    isRefetching,
  } = useUserInfoDrawer({ open, userId, onClose })

  const isView = open === EActionType.view
  const isEdit = open === EActionType.edit

  return (
    <Drawer
      title={open?.toString()}
      placement="right"
      size="large"
      onClose={onClose}
      open={!!open}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          {open !== EActionType.view && (
            <Button type="primary" onClick={handleSubmit}>
              {open}
            </Button>
          )}
        </Space>
      }
    >
      <Spin spinning={isLoading || isRefetching}>
        {contextHolder}
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          disabled={[EActionType.view, EActionType.disable, null].includes(open)}
        >
          <Form.Item
            label="username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input readOnly={isView || isEdit} />
          </Form.Item>
          {open === EActionType.create && (
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
          >
            <Input readOnly={isView} />
          </Form.Item>
          <Form.Item
            label="Role"
            name="roleids"
            rules={[{ required: true, message: 'Please choose your role!' }]}
          >
            <Select
              loading={isLoadingRole}
              mode="multiple"
              options={roles?.data?.map((role: { roleid: any; name: any }) => ({
                value: role?.roleid,
                label: role?.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Miniapp"
            name="ownerapp"
            rules={[{ required: true, message: 'Please choose your miniapp!' }]}
          >
            <Select
              loading={isLoadingMiniappData}
              mode="multiple"
              options={[{ miniapid: 'All', name: 'All' }, ...(miniapps?.data?.data || [])]?.map(
                (mna) => ({
                  value: mna?.miniapid,
                  label: mna?.name,
                }),
              )}
            />
          </Form.Item>
          {open !== EActionType.create && (
            <>
              <Form.Item label="User inactive" name="disabled" valuePropName="checked">
                <Switch size="small" />
              </Form.Item>
              <Form.Item label="Created At" name="created_at">
                <DatePicker showTime open={false} allowClear={false} inputReadOnly />
              </Form.Item>
              <Form.Item label="Updated At" name="updated_at">
                <DatePicker showTime open={false} allowClear={false} inputReadOnly />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Drawer>
  )
}

export default UserInfoDrawer
export type { UserInfoDrawerProps }
