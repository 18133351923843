import React, { FC } from 'react'

import { Col, Layout, Row, Space } from 'antd'

import CurrentDateStatistic from './components/CurrentDateStatistic'
import DateRangeStatistic from './components/DateRangeStatistic'
import SystemStatistic from './components/SystemStatistic'

const RemoteLoggerStatistic: FC = () => (
  <Layout.Content style={{ marginTop: 12 }}>
    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
      {/* system statistis */}
      <Row>
        <Col span={12}>
          <SystemStatistic />
        </Col>
        <Col span={12}>
          <CurrentDateStatistic />
        </Col>
      </Row>
      {/* filter statistis */}
      <DateRangeStatistic />
    </Space>
  </Layout.Content>
)

export default RemoteLoggerStatistic
export const remoteLoggerStatisticRouter = 'statistic'
