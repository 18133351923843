import React, { createContext, useMemo } from 'react'

import { Localization, MiniAppConfig } from '@momo-miniapp/template'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

import { useAppContext } from './AppContext'

export interface TranslateData {
  type: 'common' | 'screen' | 'component'
  name: string | 'common'
  payload: any
  screenName?: string
}

const TranslateContext = createContext<{
  data?: TranslateData
  setTranslateData: (data?: TranslateData) => void
  showTranslateModal: boolean
  setShow: (show: boolean) => void
  commonLocalizations?: Localization
  projectId?: string
  isMultiLanguage: boolean
  project?: MiniAppConfig
  isShowComfirm?: boolean
  setShowComfirm?: (isShow: boolean) => void
}>({
  data: undefined,
  setTranslateData: () => {},
  showTranslateModal: false,
  setShow: () => {},
  commonLocalizations: {},
  isMultiLanguage: false,
  isShowComfirm: false,
  setShowComfirm: () => {},
})

export const TranslateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [translate, setTranslate] = React.useState<TranslateData | undefined>(undefined)
  const [showTranslateModal, setShowTranslateModal] = React.useState<boolean>(false)
  const [isShowComfirm, setIsShowComfirm] = React.useState<boolean>(false)

  const setShowComfirm = React.useCallback(
    (isShow: boolean) => {
      setIsShowComfirm(isShow)
    },
    [setIsShowComfirm],
  )

  const { configs } = useAppContext()
  const { projectId } = useParams()

  const project = useMemo(
    () => (configs || []).find((cfg) => cfg.id === projectId),
    [configs, projectId],
  )

  const commonLocalizations = useMemo(() => (project ? project.commonLocalizations : {}), [project])
  const isMultiLanguage = useMemo(() => !isEmpty(commonLocalizations), [commonLocalizations])

  const setTranslateData = React.useCallback(
    (data?: TranslateData) => {
      setTranslate(data || undefined)
    },
    [setTranslate],
  )

  const setShow = React.useCallback(
    (show: boolean) => {
      setShowTranslateModal(show)
    },
    [setShowTranslateModal],
  )

  const value = React.useMemo(
    () => ({
      data: translate,
      setTranslateData,
      showTranslateModal,
      setShow,
      commonLocalizations,
      projectId,
      isMultiLanguage,
      project,
      isShowComfirm,
      setShowComfirm,
    }),
    [
      translate,
      setTranslateData,
      showTranslateModal,
      setShow,
      commonLocalizations,
      projectId,
      isMultiLanguage,
      project,
      isShowComfirm,
      setShowComfirm,
    ],
  )

  return <TranslateContext.Provider value={value}>{children}</TranslateContext.Provider>
}

export const useTranslateContext = () => React.useContext(TranslateContext)
