import { useCallback, useState, useEffect, useContext } from 'react'

import { message, TablePaginationConfig } from 'antd'
import { pickBy } from 'lodash'
import { useQuery } from 'react-query'

import { DateFormat_MMDDYYYY } from 'constants/dateFormats'
import useCopyToClipboard from 'hooks/useClipboard'
import { get } from 'utils/Api.miniservice'

import { NetworkLoggerContext } from '../../../../../../contexts/NetworkLogerContext'
import { TableParams } from '../../../RemoteLoggerRole/model'
import { ApiReportGroupDetailResponse } from '../../model'
import { NetworkFilterCondition } from '../../useNetworkLogger'

export interface GroupApiParams {
  apiname?: string
  allPerformanceLogFlag?: boolean
  filterCond?: NetworkFilterCondition
}
const useGroupApiTable = ({
  apiname,
  allPerformanceLogFlag = false,
  filterCond,
}: GroupApiParams) => {
  const [valCopy, copy] = useCopyToClipboard()

  const [messageApi, contextHolder] = message.useMessage()
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const { setCurrentGroupedlogs } = useContext(NetworkLoggerContext)

  const getGroupDetail = useCallback(
    () =>
      get<ApiReportGroupDetailResponse>(
        allPerformanceLogFlag ? `api-reports/slow/${apiname}` : `api-reports/${apiname}`,
        pickBy({
          startDate: filterCond?.filterDate?.[0].format(DateFormat_MMDDYYYY),
          endDate: filterCond?.filterDate?.[1].format(DateFormat_MMDDYYYY),
          miniAppId: filterCond?.miniappid,
          phone: filterCond?.search,
          limit: tableParams.pagination?.pageSize,
          page: tableParams?.pagination?.current,
        }),
      ),
    [
      allPerformanceLogFlag,
      apiname,
      filterCond?.filterDate,
      filterCond?.miniappid,
      filterCond?.search,
      tableParams.pagination,
    ],
  )

  const { data, isLoading, isRefetching, refetch } = useQuery(`${apiname}`, getGroupDetail, {
    cacheTime: 60 * 1000,
    onSuccess(dataResp) {
      const { total, page: current } = dataResp.data?.at(0)?.metaData?.at(0) || {}
      setCurrentGroupedlogs(dataResp.data?.at(0)?.data || [])

      if (total !== tableParams.pagination?.total) {
        setTableParams((state) => ({
          ...state,
          pagination: {
            ...state.pagination,
            current,
            total,
          },
        }))
      }
    },
  })

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams((currentState) => ({
      ...currentState,
      pagination,
    }))
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams), JSON.stringify(filterCond)])

  return {
    data,
    isLoading,
    isRefetching,
    handleTableChange,
    copy,
    valCopy,
    messageApi,
    contextHolder,
    tableParams,
  }
}
export default useGroupApiTable
