import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

// import { cacheUtil, CacheKey } from 'utils/secureStorage'

import { useAuthContext } from 'contexts/AuthContext'

import MainMenu from './components/MainMenu'
import { RemoteLoggerProvider } from './useRemoteLogger'

const Wrapper = styled(Layout.Content)({
  minHeight: 'calc(100vh - 64px) !important',
  display: 'flex',
})

export const remoteLoggerRouter = 'logger'

const RemoteLogger: FC = () => {
  // const userLogin = cacheUtil.get(CacheKey.loginInfo)
  // const token = localStorage.getItem('token')
  const { user } = useAuthContext()
  // if (!token) {
  //   logout?.()
  //   window.location.href = '/login'
  // }

  return (
    <RemoteLoggerProvider>
      <Wrapper>
        {user && <MainMenu />}
        <Outlet />
      </Wrapper>
    </RemoteLoggerProvider>
  )
}

export default RemoteLogger
