import { useState, useEffect } from 'react'

import { message } from 'antd'
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import _, { isEmpty, pickBy } from 'lodash'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { get } from 'utils/Api.miniservice'

import useCopyToClipboard from '../../../../hooks/useClipboard'
import { GetMiniappsResponse } from '../RemoteLoggerStatistic/model'

interface TableParams {
  pagination?: TablePaginationConfig
  sortField?: string
  sortOrder?: string
  filters?: Record<string, FilterValue>
  searchTerm?: string
}

interface DocsLoggerResponse {
  apiname?: string
  createdAt?: Date
  level?: string
  miniappid?: string
  miniappname?: string
  msg?: string
  publicid: string
  stacktrace?: string
  _id?: string
}
interface LoggerResponse {
  data: {
    docs: Array<DocsLoggerResponse>
    hasNextPage: boolean
    hasPrevPage: boolean
    limit: number
    nextPage: any
    page: number
    pagingCounter: number
    prevPage: any
    totalDocs: number
    totalPages: number
  }
  message?: string
  success?: boolean
}

interface LoggerResquest {
  page?: number
  limit?: number
  order?: string
  level?: FilterValue
  miniappname?: FilterValue
  miniappid?: FilterValue
  searchTerm?: string
}

const useRemoteLoggerTable = () => {
  const navigator = useNavigate()

  const [valCopy, copy] = useCopyToClipboard()

  const [messageApi, contextHolder] = message.useMessage()
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const getRemoteLogs = async (request: LoggerResquest): Promise<LoggerResponse> => {
    const filters = {
      searchField: null,
      search: request.searchTerm,
      ...(!isEmpty(request.miniappid) && {
        miniappid: `${request.miniappid}`,
      }),
    }

    const params = {
      page: request?.page,
      limit: request?.limit,
      order: request?.order,
      level: request?.level,
      ...filters,
    }

    const resp = await get<LoggerResponse>(
      'loggers',
      _.isEmpty(request) ? { order: 'desc' } : pickBy(params),
    )
    return resp
  }

  const fetchData = () => {
    const request: LoggerResquest = {
      page: tableParams.pagination?.current,
      limit: tableParams.pagination?.pageSize,
      order: 'desc',
      level: tableParams.filters?.level,
      // since dataIndex is miniappname
      miniappid: tableParams.filters?.miniappname,
      searchTerm: tableParams?.searchTerm,
    }
    return getRemoteLogs(request)
  }

  const { data, isLoading, isRefetching, isFetching, refetch } = useQuery(
    ['remote-logger'],
    fetchData,
    {
      onSuccess: (response) => {
        if (response.data?.totalDocs !== tableParams.pagination?.total)
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: response?.data?.page,
              total: response.data?.totalDocs,
            },
          })
      },
      retry: false,
    },
  )

  const { data: miniapps, isLoading: isLoadingMiniappData } = useQuery(
    ['get-miniapps'],
    () => get<GetMiniappsResponse>('miniapp'),
    {
      cacheTime: 3 * 60 * 1000,
      retry: false,
    },
  )

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue> | any,
    sorter: SorterResult<DocsLoggerResponse> | SorterResult<DocsLoggerResponse>[],
  ) => {
    setTableParams((currentState) => ({
      ...currentState,
      pagination,
      filters,
      ...sorter,
    }))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setTableParams((state) => ({ ...state, filters: {} }))
  }

  const handleSearchInput = (searchTerm: string) => {
    setTableParams((state) => ({ ...state, searchTerm }))
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)])

  return {
    getRemoteLogs,
    navigator,
    tableParams,
    setTableParams,
    data,
    isLoading,
    isRefetching,
    isFetching,
    refetch,
    handleTableChange,
    handleReset,
    handleSearchInput,
    messageApi,
    contextHolder,
    copy,
    valCopy,
    miniapps,
    isLoadingMiniappData,
  }
}

export default useRemoteLoggerTable
export type { LoggerResponse, DocsLoggerResponse, LoggerResquest }
