/* eslint-disable react/no-array-index-key */

import React, { FC, useCallback } from 'react'

import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import styled from '@emotion/styled'
import { Input, Form, Layout, Row, Col, FloatButton } from 'antd'
import { FormItemProps } from 'antd/es/form/FormItem'
import Card from 'antd/lib/card/Card'
import moment from 'moment'
import ReactJson from 'react-json-view'

import { DateFormat_DDMMYYHHmm } from 'constants/dateFormats'
import { safeJsonParse } from 'utils/isJson'

import CustomLevelTag from './components/CustomTag'
import useRemoteLoggerDetail from './useRemoteLogerDetail'

const remoteLoggerDetailRouter = 'log-detail/:idLog'

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
`

enum FormType {
  input = 'input',
  tag = 'tag',
  date = 'date',
}
interface FormItemDef extends FormItemProps {
  type?: FormType
  onChange?: FunctionConstructor
  readonly?: boolean
}

const formItemDef: Array<FormItemDef> = [
  {
    label: 'Level',
    name: 'level',
    type: FormType.tag,
    readonly: true,
  },
  {
    label: 'Created At',
    name: 'createdAt',
    type: FormType.date,
    readonly: true,
  },
  {
    label: 'Api Name',
    name: 'apiname',
    type: FormType.input,
    readonly: true,
  },
  {
    label: 'Miniapp Name',
    name: 'miniappname',
    type: FormType.input,
    readonly: true,
  },
  {
    label: 'Miniapp Id',
    name: 'miniappid',
    type: FormType.input,
    readonly: true,
  },
  // {
  //   label: 'Publicid',
  //   name: 'publicid',
  //   type: FormType.input,
  //   readonly: true,
  // },
]

const RemoteLoggerDetail: FC = () => {
  const { data, isLoading, form, palette } = useRemoteLoggerDetail()

  const renderFormItem = () =>
    formItemDef.map((item, index) => {
      switch (item?.type) {
        case FormType.tag:
          return (
            <Row key={index}>
              <Col span={24}>
                <Form.Item label={<Title>{item?.label}</Title>}>
                  <CustomLevelTag level={data?.data?.level} />
                </Form.Item>
              </Col>
            </Row>
          )
        case FormType.date:
          return (
            <Form.Item key={index} label={<Title>{item?.label}</Title>}>
              {moment(data?.data?.createdAt).format(DateFormat_DDMMYYHHmm).toString()}
            </Form.Item>
          )
        case FormType.input:
        default:
          return (
            <Row key={index}>
              <Col span={24}>
                <Form.Item
                  label={<Title>{item?.label}</Title>}
                  name={item?.name}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input readOnly={item.readonly} />
                </Form.Item>
              </Col>
            </Row>
          )
      }
    })

  const StackstraceDiv = styled.div`
    min-height: 50px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-wrap: break-word;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: ${palette?.primary.toString()};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `

  const handleClose = useCallback(() => window.close(), [])

  return (
    <Layout.Content
      style={{
        margin: 12,
        width: '100%',
      }}
    >
      <Card
        title={
          <>
            {data?.title} <i>({safeJsonParse(data?.data?.msg || '')?.eventname})</i>
          </>
        }
        extra={<CloseCircleFilled onClick={handleClose} style={{ fontSize: 30, color: 'red' }} />}
      >
        Api Name: <i>{data?.data?.apiname || ''}</i>
      </Card>
      <Row>
        <Col span={8}>
          <Card loading={isLoading}>
            <Form form={form} initialValues={data?.data}>
              {renderFormItem()}
            </Form>
          </Card>
        </Col>
        <Col span={16}>
          <Card title="Stackstace" loading={isLoading}>
            {data?.data?.stacktrace && <StackstraceDiv>{data?.data?.stacktrace}</StackstraceDiv>}
          </Card>
          <Card title="Message" loading={isLoading}>
            <ReactJson src={safeJsonParse(data?.data?.msg)} />
          </Card>
        </Col>
      </Row>
      <FloatButton.BackTop />
    </Layout.Content>
  )
}

export default RemoteLoggerDetail
export { remoteLoggerDetailRouter }
