import React, { FC, memo } from 'react'

import { Card } from 'antd'
import _ from 'lodash'
import { ResponsiveContainer, BarChart, CartesianGrid, YAxis, Legend, Bar, Cell } from 'recharts'

import { MiniappLogKeys, LogColors } from 'constants/commonValues'

import { ExtendMiniappLogFigures } from '../../model'

interface CustomChartToolTipProps {
  mnaInfo: ExtendMiniappLogFigures
}
const CustomChartToolTip: FC<CustomChartToolTipProps> = ({ mnaInfo }) => {
  type MnaKeyType = keyof ExtendMiniappLogFigures

  const mnaCData = Object.keys(mnaInfo || {})
    ?.map((key) => {
      const remap = { name: key as string, value: mnaInfo[key as MnaKeyType] as number }
      return remap
    })
    .filter((mna) => MiniappLogKeys.includes(mna?.name))

  return (
    <Card title={`${mnaInfo?.miniappid}`} style={{ opacity: 0.9 }}>
      <ResponsiveContainer minWidth={320} height={150}>
        <BarChart width={300} height={300} data={mnaCData}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <Legend
            payload={mnaCData.map((item) => ({
              id: item?.name,
              type: 'square',
              value: `${item.name}`,
              color: LogColors[MiniappLogKeys.indexOf(item?.name)],
            }))}
          />
          <Bar dataKey="value" minPointSize={5}>
            {(mnaCData || []).map((entry: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={`cell-${index}`} fill={LogColors[MiniappLogKeys.indexOf(entry?.name)]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default memo(CustomChartToolTip, (prevProps, nextProps) => _.isEqual(prevProps, nextProps))
export type { CustomChartToolTipProps }
