import { HardAdminCode } from 'constants/commonValues'

import { JWTToken, parseJwt } from './jwt'

const isTemplateAdmin = () => {
  try {
    const token = localStorage.getItem('token')
    if (!token) {
      return false
    }
    const decodedToken = parseJwt<JWTToken>(token || '')
    const isAdmin = (decodedToken?.user?.role || []).includes(HardAdminCode)
    return isAdmin
  } catch (error) {
    return false
  }
}

export default isTemplateAdmin
