import React, { FC, useEffect } from 'react'

import GlobalOutlined from '@ant-design/icons/GlobalOutlined'
import LinkOutlined from '@ant-design/icons/LinkOutlined'
import MenuOutlined from '@ant-design/icons/MenuOutlined'
import styled from '@emotion/styled'
import { Button, Drawer, Layout, Menu, Space } from 'antd'
import { ArrowLeft, CaretRight, List } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import AppListButton from 'components/AppListButton'
import { AppLogo } from 'components/AppLogo'
import AuthButton from 'components/AuthButton.tsx/AuthButton'
import { TranslateGlobal } from 'components/TranslateGlobal'
import { ModalProvider } from 'contexts/ModalContext'
import { TranslateContextProvider } from 'contexts/TranslateContext'
import mq, { useWindowSize } from 'utils/mediaQuery'

interface RootLayoutProps {
  children?: React.ReactNode
}

const Header = styled(Layout.Header)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingInline: '20px !important',
  width: '100%',
  overflow: 'hidden',
})

const HeaderAppLogo = styled(AppLogo)({
  marginTop: 12,
  marginBottom: 12,
  minWidth: 70,
})

const HeaderMenu = styled(Menu)({
  minWidth: 0,
  width: '100%',
  [mq('sm')]: {
    color: '#fff',
    flex: 'auto',
    backgroundColor: 'transparent',
    display: 'flex',
    lineHeight: '46px !important',
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
  borderInlineEnd: 'none !important',
  '& .ant-menu-item': {
    borderRadius: '8px !important',
    marginLeft: '0px !important',
    [mq('sm')]: {
      marginLeft: '8px !important',
    },
  },
  '& .ant-menu-item-active, .ant-menu-item-selected': {
    [mq('sm')]: {
      color: '#fff !important',
    },
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
  },
  '& .ant-menu-item-active::after, .ant-menu-item-selected::after': {
    borderBottomColor: 'transparent !important',
  },
  '& .ant-menu-submenu-title': {
    [mq('sm')]: {
      color: '#fff !important',
    },
  },
})

const Navigation = styled(Space)({
  width: '100%',
  flexGrow: 1,
  flex: 1,
  justifyContent: 'flex-end',
})

const HamburgerButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
})

const MobileMenu = styled(Drawer)((props) => ({
  '.ant-drawer-body': {
    padding: `${props.theme.paddingXL}px ${props.theme.paddingXS}px`,
  },
  '.ant-drawer-header': {
    borderBottom: 'none',
    padding: `${props.theme.padding}px ${props.theme.padding}px`,
  },
}))

const CustomRightOutlined = styled(CaretRight)(() => ({
  color: '#fff',
  marginInline: 10,
}))

const RootLayout: FC<RootLayoutProps> = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  const { breakpoint } = useWindowSize()

  const selectedKeys = pathname.split('/').slice(1)

  const shouldShowMobileMenu = breakpoint === 'xs' || breakpoint === 'xxs'

  useEffect(() => {
    if (!shouldShowMobileMenu) {
      setShowMobileMenu(false)
    }
  }, [shouldShowMobileMenu])

  const renderMenu = () => (
    <HeaderMenu
      mode={shouldShowMobileMenu ? 'vertical' : 'horizontal'}
      selectedKeys={[...selectedKeys, i18n.language]}
      overflowedIndicator={<MenuOutlined />}
      items={[
        {
          key: 'dashboard',
          label: t('layout.navigation.dashboard'),
          onClick: () => {
            navigate('/dashboard')
            setShowMobileMenu(false)
          },
        },
        {
          key: 'documentation',
          label: t('layout.navigation.documentation'),
          onClick: () => {
            window.open('/documentation', '_blank')
            setShowMobileMenu(false)
          },
        },
        {
          key: 'mac',
          label: 'Mini App Center ',
          icon: <LinkOutlined />,
          onClick: () => {
            window.open('https://developers.momoapp.vn/', '_blank')
            setShowMobileMenu(false)
          },
        },
        {
          key: 'locale',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: t(`locales.${i18n.language}` as any),
          icon: <GlobalOutlined />,
          children: [
            {
              key: 'en',
              label: 'English',
              onClick: () => i18n.changeLanguage('en'),
            },
            {
              key: 'vi',
              label: 'Tiếng Việt',
              onClick: () => i18n.changeLanguage('vi'),
            },
          ],
        },
      ]}
    />
  )

  return (
    <ModalProvider>
      <TranslateContextProvider>
        <Layout>
          <Header>
            <Link to="/" style={{ lineHeight: '36px' }}>
              <HeaderAppLogo />
            </Link>
            <AppListButton preffixIcon={<CustomRightOutlined />} />
            <TranslateGlobal preffixIcon={<CustomRightOutlined />} />
            <Navigation>
              {shouldShowMobileMenu ? (
                <HamburgerButton size="large" type="ghost" onClick={() => setShowMobileMenu(true)}>
                  <List size={24} />
                </HamburgerButton>
              ) : (
                renderMenu()
              )}
              <AuthButton />
            </Navigation>
          </Header>
          <Outlet />
          <MobileMenu
            open={showMobileMenu}
            onClose={() => setShowMobileMenu(false)}
            placement="right"
            width={300}
            closeIcon={<ArrowLeft size={24} />}
          >
            {renderMenu()}
          </MobileMenu>
        </Layout>
      </TranslateContextProvider>
    </ModalProvider>
  )
}

export default RootLayout
