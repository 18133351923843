import React from 'react'

import styled from '@emotion/styled'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { FallInPlace } from 'components/motion'
import mq from 'utils/mediaQuery'

import FeatureItem from './FeatureItem'

interface FeaturesProps {
  className?: string
}

const Wrapper = styled.div((props) => ({
  backgroundColor: props.theme.colorBgContainer,
  padding: `${props.theme.paddingXL}px ${props.theme.padding}px`,
}))

const Container = styled.div(() => ({
  maxWidth: 1024,
  margin: '0 auto',
}))

const Grid = styled.div({
  display: 'grid',
  gap: '64px',
  paddingBottom: '64px',
  margin: '64px 0 0',
  gridTemplateColumns: '1fr',

  [mq('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    gap: '32px 32px',
    paddingBottom: '128px',
  },

  [mq('md')]: {
    gap: '128px 128px',
  },
})

const FeatureGrid = styled(Grid)({
  [mq('sm')]: {
    '& > div:nth-of-type(2n)': {
      transform: 'translateY(50%)',
    },
  },
})

const ComingSoonGrid = styled(Grid)({
  paddingBottom: 64,
  marginTop: 0,
  [mq('sm')]: {
    marginTop: 128,
  },
})

const Features: React.FC<FeaturesProps> = ({ className }) => {
  const { t } = useTranslation()

  const features = [
    {
      title: t('landing.features.feature1.title'),
      description: t('landing.features.feature1.description'),
      video: '/videos/feat-1.webm',
    },
    {
      title: t('landing.features.feature2.title'),
      description: t('landing.features.feature2.description'),
      video: '/videos/feat-2.webm',
    },
    {
      title: t('landing.features.feature3.title'),
      description: t('landing.features.feature3.description'),
      video: '/videos/feat-3.webm',
    },
    {
      title: t('landing.features.feature4.title'),
      description: t('landing.features.feature4.description'),
      video: '/videos/feat-4.webm',
    },
    {
      title: t('landing.features.feature5.title'),
      description: t('landing.features.feature5.description'),
      video: '/videos/feat-5.webm',
    },
    {
      title: t('landing.features.feature6.title'),
      description: t('landing.features.feature6.description'),
      video: '/videos/feat-6.webm',
    },
  ]

  const comingSoonFeatures = [
    {
      title: t('landing.features.feature7.title'),
      description: t('landing.features.feature7.description'),
      isComingSoon: true,
    },
    {
      title: t('landing.features.feature8.title'),
      description: t('landing.features.feature8.description'),
      isComingSoon: true,
    },
  ]

  return (
    <Wrapper className={className}>
      <Container>
        <FallInPlace>
          <Typography.Title level={2}>{t('landing.features.title')}</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 18 }} type="secondary">
            {t('landing.features.description')}
          </Typography.Paragraph>
        </FallInPlace>
        <FeatureGrid>
          {features.map((feature) => (
            <FeatureItem key={feature.title} {...feature} />
          ))}
        </FeatureGrid>
        <ComingSoonGrid>
          {comingSoonFeatures.map((feature) => (
            <FeatureItem key={feature.title} {...feature} />
          ))}
        </ComingSoonGrid>
      </Container>
    </Wrapper>
  )
}

export default Features
