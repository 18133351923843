import React, { FC, useCallback } from 'react'

import CloseOutlined from '@ant-design/icons/CloseOutlined'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import styled from '@emotion/styled'
import { Input } from 'antd'
import debounce from 'lodash/debounce'

const RoundedInput = styled(Input)`
  .ant-input {
    border-radius: 25px;
  }
`

const RoundedSearch = styled(Input.Search)`
  .ant-input-wrapper.ant-input-group {
    .ant-input-affix-wrapper {
      border-radius: 25px 0 0 25px;
    }
    .ant-input-group-addon {
      border-radius: 0 25px 25px 0;
      .ant-input-search-button {
        border-radius: 0 25px 25px 0;
      }
    }
  }
`

const debounceTime = 2 * 1000
interface SearchInputProps {
  onSearch: (value: string) => void
  placeholder?: string
  withoutDebounce?: boolean
  loading?: boolean
}

const SearchInput: FC<SearchInputProps> = ({
  onSearch,
  placeholder,
  withoutDebounce = false,
  loading = false,
}) => {
  const handleSearch = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      const target = evt.target as HTMLTextAreaElement
      onSearch(target?.value)
    },
    [onSearch],
  )

  const handleClear = useCallback(() => {
    onSearch('')
  }, [onSearch])

  const debouncedChangeHandler = useCallback(
    (event: { target: { value: string } }) => onSearch(event?.target?.value),
    [onSearch],
  )

  return withoutDebounce ? (
    <RoundedSearch
      placeholder={placeholder || 'Search'}
      enterButton="Search"
      style={{ borderStartStartRadius: 25 }}
      prefix={<SearchOutlined />}
      allowClear={{ clearIcon: <CloseOutlined onClick={handleClear} /> }}
      onSearch={onSearch}
      loading={loading}
    />
  ) : (
    <RoundedInput
      placeholder={placeholder || 'Search'}
      prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
      allowClear={{ clearIcon: <CloseOutlined onClick={handleClear} /> }}
      style={{ borderRadius: 25 }}
      onChange={debounce(debouncedChangeHandler, debounceTime)}
      onPressEnter={handleSearch}
    />
  )
}

export default SearchInput
