/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { CheckCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { MiniAppConfig } from '@momo-miniapp/template'
import { Skeleton, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

interface AppItemProps {
  className?: string
  data: MiniAppConfig
  loading?: boolean
}

const TemplateItemWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
})

const Wrapper = styled.div<{ loading?: boolean }>(({ loading }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  pointerEvents: loading ? 'none' : 'unset',
}))

const Container = styled.div({
  marginLeft: 16,
})

const AppLogo = styled.img({
  width: 64,
  height: 64,
  borderRadius: 16,
  objectFit: 'contain',
})

const AppName = styled(Typography.Title)({
  fontSize: '14px !important',
})

const StatusDot = styled.div<{ status: 'published' | 'draft' }>(
  {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  (props) => ({
    backgroundColor: props.status === 'published' ? '#00C853' : 'rgba(68,79,108,.3)',
  }),
)

const StatusLabel = styled(Typography.Paragraph)({
  marginBottom: '0px !important',
  fontSize: '12px !important',
  fontWeight: 500,
})

const CheckboxWrapper = styled.div({})

const IconStyle = styled(CheckCircleOutlined)({})

const TemplateItem: React.FC<AppItemProps> = ({ className, data, loading }) => {
  const { t } = useTranslation()

  return (
    <TemplateItemWrapper>
      <Wrapper className={className} loading={loading}>
        {!loading ? (
          <AppLogo src={data.appLogo || '/images/momo-icon.png'} />
        ) : (
          <Skeleton.Avatar shape="square" size={50} active style={{ borderRadius: 15 }} />
        )}
        <Container>
          {!loading ? <AppName level={5}>{data.miniAppName}</AppName> : <Skeleton.Input active />}
          {!loading ? (
            <Space>
              <StatusDot status="draft" />
              <StatusLabel type="secondary">{t(`dashboard.appCard.status.draft`)}</StatusLabel>
            </Space>
          ) : null}
        </Container>
      </Wrapper>
      <CheckboxWrapper>
        <IconStyle />
      </CheckboxWrapper>
    </TemplateItemWrapper>
  )
}

export default TemplateItem
