import { useEffect } from 'react';

import { Form, message, Modal } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { get, post } from 'utils/Api.miniservice';

import { GetRoleResponse } from '../../../RemoteLoggerRole/model';
import { GetMiniappsResponse } from '../../../RemoteLoggerStatistic/model';
import {
  DeleteUserResponse,
  GetUserDetailResponse,
  PostUserRequest,
  PostUserResponse,
  PutUserRequest,
  PutUserResponse,
} from '../../model';
import useRLUserManagement, { EActionType } from '../../useRLUserManagement';

// eslint-disable-next-line import/no-cycle
import { UserInfoDrawerProps } from './UserInfoDrawer';

type UserInfoDrawerParams = UserInfoDrawerProps;

const useUserInfoDrawer = ({ open, userId, onClose }: UserInfoDrawerParams) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const { refetch: refetchUserData } = useRLUserManagement();

  const fetchData = async () => {
    if (userId) {
      const resp: GetUserDetailResponse | undefined = await get<GetUserDetailResponse>(
        `user/${userId}`
      );
      if (resp && !isEmpty(resp.data)) {
        const userDetail = structuredClone(resp.data);
        userDetail.created_at = dayjs(userDetail?.created_at);
        userDetail.updated_at = dayjs(userDetail?.updated_at);
        return userDetail;
      }
    }
    return null;
  };

  const { isLoading, isRefetching, refetch } = useQuery(['user-detail'], fetchData, {
    onSuccess: (data) => {
      form.setFieldsValue(data);
    },
    retry: false
  });

  const { data: miniapps, isLoading: isLoadingMiniappData } = useQuery(
    ['get-miniapps'],
    () => get<GetMiniappsResponse>('miniapp'),
    {
      cacheTime: 3 * 60 * 1000,
      retry: false
    }
  );

  const { data: roles, isLoading: isLoadingRole } = useQuery(
    ['get-role-select'],
    () => get<GetRoleResponse>('role'),
    {
      cacheTime: 3 * 60 * 1000,
      retry: false
    }
  );

  useEffect(() => {
    form.resetFields();
    switch (open) {
      case EActionType.create:
      case EActionType.view:
      case EActionType.edit:
      case EActionType.disable:
        refetch();
        break;
      default:
        break;
    }
  }, [form, open, refetch, userId]);

  const handleSubmit = async () => {
    const { username, ownerapp, roleids, email, password, disabled } = form.getFieldsValue();
    // eslint-disable-next-line default-case
    switch (open) {
      case EActionType.create:
        await form.validateFields();
        try {
          const request: PostUserRequest = {
            username,
            password,
            email,
            roleids,
            ownerapp,
          };
          const resp = await post<PostUserRequest, PostUserResponse>(`user`, request);
          if (resp.status === 'success') messageApi.success(`Create new user ${username} success`);
          refetchUserData();
        } catch (error: any) {
          messageApi.error(error.response.data.message);
        }
        break;
      case EActionType.edit:
        await form.validateFields();
        try {
          const request: PutUserRequest = {
            email,
            roleids,
            ownerapp,
            disabled,
          };
          const resp = await post<PutUserRequest, PutUserResponse>(
            `user/update/${userId}`,
            request
          );
          if (resp.status === 'success') messageApi.success(`Update user ${username} success`);
          refetchUserData();
        } catch (error: any) {
          messageApi.error(error.response.data.message);
        }
        break;
      case EActionType.disable:
        Modal.confirm(
          {
            title: 'Warning',
            content: `Are you sure you want to disable user ${username}?`,
            onOk: async () => {
              try {
                const resp = await post<typeof undefined, DeleteUserResponse>(`user/delete/${userId}`);
                if (resp?.status === 'success') messageApi.success(`Delete user ${username} success`);
                refetchUserData();
              } catch (error: any) {
                messageApi.error(error.response.data.message);
              }
            }
          }
        );
        break;
      case EActionType.view:
        onClose();
        break;
    }
  };

  return {
    form,
    miniapps,
    isLoadingMiniappData,
    isLoading,
    isRefetching,
    handleSubmit,
    roles,
    isLoadingRole,
    contextHolder,
  };
};

export default useUserInfoDrawer;
