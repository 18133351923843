import React, { FC, useCallback } from 'react'

import { Spin, Typography } from 'antd'
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Tooltip } from 'recharts'

import useCurrentTimeOlock from '../../hooks/useCurrentTimeOlock'
import CustomChartToolTip from '../CustomChartToolTip/CustomChartToolTip'

import useCurrentDateStatistic from './useCurrentDateStatistic'

type OlockProps = {
  label?: string
}

const Olock: FC<OlockProps> = ({ label }) => {
  const { currentDate } = useCurrentTimeOlock()
  return (
    <>
      {label} <i>{currentDate}</i>
    </>
  )
}

const CurrentDateStatistic: FC = () => {
  const {
    data,
    activeIndex,
    currentMiniappCData,
    LogColors,
    onPieEnter,
    MiniappLogKeys,
    getNameMiniappById,
    isLoading,
    isFetching,
  } = useCurrentDateStatistic()

  const renderActiveShape = useCallback(
    (props: any) => {
      const RADIAN = Math.PI / 180
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
      } = props || {}
      const sin = Math.sin(-RADIAN * midAngle)
      const cos = Math.cos(-RADIAN * midAngle)
      const sx = cx + (outerRadius + 10) * cos
      const sy = cy + (outerRadius + 10) * sin
      const mx = cx + (outerRadius + 30) * cos
      const my = cy + (outerRadius + 30) * sin
      const ex = mx + (cos >= 0 ? 1 : -1) * 22
      const ey = my
      const textAnchor = cos >= 0 ? 'start' : 'end'

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {getNameMiniappById(payload?.miniappid)}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >{`${getNameMiniappById(payload?.miniappid)}: ~${Math.round(value)} logs`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(Rate ${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      )
    },
    [getNameMiniappById],
  )

  const CustomTooltip = useCallback(
    ({ active, payload }: any) => {
      if (active && payload && payload.length) {
        const miniappData = data[activeIndex]
        return <CustomChartToolTip mnaInfo={miniappData} />
      }
      return null
    },
    [data, activeIndex],
  )

  return (
    <Spin spinning={isLoading || isFetching}>
      <Typography.Title level={5} style={{ alignSelf: 'flex-end' }}>
        <Olock label="Current Date:" />
      </Typography.Title>
      <ResponsiveContainer height={350}>
        <PieChart height={300} width={300}>
          <Tooltip content={<CustomTooltip />} />
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={90}
            outerRadius={120}
            fill="#8884d8"
            dataKey="sum"
            onMouseEnter={onPieEnter}
          />
          <Pie
            data={currentMiniappCData}
            cx="50%"
            cy="50%"
            labelLine={false}
            innerRadius={60}
            outerRadius={80}
            dataKey="value"
          >
            {(currentMiniappCData || []).map((entry: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={`cell-${index}`} fill={LogColors[MiniappLogKeys.indexOf(entry?.name)]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Spin>
  )
}

export default CurrentDateStatistic
