import React, { FC } from 'react'

import CopyTwoTone from '@ant-design/icons/CopyTwoTone'
import EyeTwoTone from '@ant-design/icons/EyeTwoTone'
import styled from '@emotion/styled'
import { Card, Space, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import moment from 'moment'

import { DateFormat_DDMMYYHHmm } from 'constants/dateFormats'
import { remoteLoggerRouter } from 'modules/RemoteLogger/RemoteLogger'
import { convertMillisecondsToHoursMinutesSeconds } from 'utils/time'

import SearchInput from '../../../../components/SearchInput/SearchInput'
import { safeJsonParse } from '../../../../utils/isJson'
import { remoteLoggerDetailRouter } from '../RemoteLoggerDetail'
import { ELevelTag } from '../RemoteLoggerDetail/components'
import CustomLevelTag from '../RemoteLoggerDetail/components/CustomTag'

import useRemoteLoggerTable, { DocsLoggerResponse } from './useRemoteLoggerTable'

const remoteLoggerTableRouter = 'logs'

const StacktraceDiv = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ReqDuration = styled.span`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: oblique;
  font-size: 0.85em;
`

const PageContainer = styled.div`
  margin: 12px;
  width: 100%;
`

const RemoteLoggerTable: FC = () => {
  const {
    isLoading,
    isFetching,
    tableParams,
    data,
    handleTableChange,
    handleSearchInput,
    messageApi,
    contextHolder,
    copy,
    miniapps,
  } = useRemoteLoggerTable()

  const columns: ColumnsType<DocsLoggerResponse> = [
    {
      title: 'Mini Appname',
      dataIndex: 'miniappname',
      filterMode: 'menu',
      filterSearch: true,
      filters: miniapps?.data?.data?.map((mna) => ({
        text: mna?.name || mna?.miniappname,
        value: mna?.miniapid,
      })),
      filterMultiple: true,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      render: (level) => <CustomLevelTag level={level} />,
      filters: [
        {
          text: ELevelTag.info,
          value: ELevelTag.info,
        },
        {
          text: ELevelTag.warn,
          value: ELevelTag.warn,
        },
        {
          text: ELevelTag.error,
          value: ELevelTag.error,
        },
      ],
      filterMultiple: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (createdDate) => moment(createdDate).format(DateFormat_DDMMYYHHmm),
    },
    {
      title: 'Event Name',
      dataIndex: 'msg',
      render: (msg) => <StacktraceDiv>{safeJsonParse(msg)?.eventname}</StacktraceDiv> || '',
      width: '20%',
    },
    {
      title: 'Api Name',
      dataIndex: 'msg',
      render: (msg) => <StacktraceDiv>{safeJsonParse(msg)?.apiname}</StacktraceDiv> || '',
      width: '35%',
    },
    {
      title: 'Duration',
      dataIndex: 'msg',
      render: (msg) => {
        const requestDuration = safeJsonParse(msg)?.requestDuration
        return requestDuration ? (
          <>
            {/* <Badge color="#f50" size="small" style={{ fontStyle: 'oblique' }} /> */}
            <ReqDuration>{convertMillisecondsToHoursMinutesSeconds(requestDuration)}</ReqDuration>
          </>
        ) : (
          ''
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'publicid',
      fixed: true,
      render: (publicid) => {
        const urlDetail = [
          window.location.origin,
          remoteLoggerRouter,
          remoteLoggerDetailRouter.replaceAll(':idLog', publicid),
        ].join('/')
        return (
          <Space direction="horizontal" size={10}>
            <Tooltip title="View detail log" color="cyan">
              <EyeTwoTone onClick={() => window.open(urlDetail, '_blank')} />
            </Tooltip>
            <Tooltip title="Copy link" color="cyan">
              <CopyTwoTone
                onClick={() => {
                  copy(urlDetail)
                  messageApi.success({
                    content: 'Copied link to Clipboard',
                    duration: 2,
                  })
                }}
              />
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  return (
    <PageContainer>
      {contextHolder}
      <Card style={{ width: '100%', borderRadius: 8 }}>
        <Space direction="vertical" style={{ width: '20%', paddingBottom: 10 }}>
          <SearchInput onSearch={handleSearchInput} loading={isLoading || isFetching} />
        </Space>
        <Table
          // scroll={{ y: 500, x: 500 }}
          columns={columns}
          rowKey={(record) => record.publicid}
          dataSource={data?.data?.docs}
          pagination={tableParams.pagination}
          loading={isLoading || isFetching}
          onChange={handleTableChange}
        />
      </Card>
    </PageContainer>
  )
}
export default RemoteLoggerTable
export { remoteLoggerTableRouter }
