import { useCallback, useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { pickBy } from 'lodash'
import { RangeValue } from 'rc-picker/lib/interface'
import { useQuery } from 'react-query'

import { MiniappDefaultColors, MiniappLogKeys, LogColors } from 'constants/commonValues'
import { DateFormat_MMDDYYYY } from 'constants/dateFormats'
import { get } from 'utils/Api.miniservice'

import {
  GetMiniappsResponse,
  GetRangeDateStatisticResponse,
  GroupedDateStatistic,
  MiniappDefinition,
} from '../../model'

export type LogFilterCondition = {
  filterDate?: [dayjs.Dayjs, dayjs.Dayjs]
  miniappid?: string
}

export const useDateRangeStatistic = () => {
  const [data, setData] = useState<GroupedDateStatistic[]>()

  const [filterCondition, setFilterCondition] = useState<LogFilterCondition>({
    filterDate: [dayjs().subtract(1, 'months'), dayjs()],
  })

  const onChangeMiniapp = useCallback(
    (miniapp: MiniappDefinition | null) =>
      setFilterCondition((prevState) => ({ ...prevState, miniappid: miniapp?.miniapid })),
    [],
  )
  const getNameMiniappById = (id: string) => (id ? id?.split('.')?.at(-1) : '')
  const getColorMiniappById = (id: string) =>
    MiniappDefaultColors[id] || `#${Math.floor(Math.random() * 16777215).toString(16)}`

  const applyFilter = () => {
    const request = {
      startDate: filterCondition.filterDate?.[0].format(DateFormat_MMDDYYYY),
      endDate: filterCondition.filterDate?.[1].format(DateFormat_MMDDYYYY),
      miniAppId: filterCondition.miniappid,
    }
    return get<GetRangeDateStatisticResponse>('chart', pickBy(request))
  }

  const { isFetched, isFetching, isLoading, isRefetching, refetch } = useQuery(
    ['chart-range-date'],
    applyFilter,
    {
      refetchInterval: 20 * 1000,
      onSuccess: (resp) => {
        setData(
          (resp.data || []).map((mna) => ({
            ...mna,
            date: dayjs(mna.date).format(DateFormat_MMDDYYYY),
          })),
        )
      },
      retry: false,
    },
  )

  const { data: miniapps, isLoading: isLoadingMiniappData } = useQuery(
    ['get-miniapps'],
    () => get<GetMiniappsResponse>('miniapp'),
    {
      cacheTime: 3 * 60 * 1000,
      retry: false,
    },
  )

  const onChangeDate = useCallback((selectedRange: RangeValue<dayjs.Dayjs>) => {
    setFilterCondition(
      (prevState: LogFilterCondition) =>
        ({
          ...prevState,
          filterDate: selectedRange,
        } as LogFilterCondition),
    )
  }, [])

  useEffect(() => {
    refetch()
  }, [refetch, filterCondition])

  return {
    filterCondition,
    setFilterCondition,
    onChangeDate,
    applyFilter,
    onChangeMiniapp,
    data,
    isFetched,
    isFetching,
    isLoading,
    isRefetching,
    MiniappLogKeys,
    LogColors,
    refetch,
    miniapps,
    isLoadingMiniappData,
    getNameMiniappById,
    getColorMiniappById,
  }
}
