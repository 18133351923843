/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import Buffer from 'buffer'

import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'

const BASE_URL = 'https://api.mservice.com.vn:8001/connect-mac'
// const BASE_URL = process.env.REACT_APP_MAC_CONNECTION_API_URL
const requestTimeout = 30 * 1000

const axiosInstanceConnection = axios.create({
  baseURL: BASE_URL,
})

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export type HttpHeaders = {
  [key: string]: string
}

export type RequestConfig = {
  headers: HttpHeaders
}

export type RefreshTokenRequest = {
  refreshToken: string
}

export type RefreshTokenReponse = {
  token: string
  refreshToken: string
  statuscode?: number
  status?: string
}

const handleServiceError = (apiError: unknown) =>
  // console.error(apiError)
  Promise.reject(apiError)

export const post = async <TRequest, TResponse>(
  path: string,
  payload?: TRequest,
  config?: RequestConfig,
): Promise<TResponse> => {
  try {
    const response = await axiosInstanceConnection.post<TResponse>(path, payload, config)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

export const get = async <TResponse>(path: string, config?: RequestConfig): Promise<TResponse> => {
  try {
    const response = await axiosInstanceConnection.get<TResponse>(path, config)
    return response?.data
  } catch (apiError) {
    return handleServiceError(apiError)
  }
}

interface AxiosConfig {
  baseURL?: string
  timeout?: number
  headers?: any
}

axiosInstanceConnection.interceptors.request.use(
  async (config: AxiosConfig) => {
    const token = localStorage.getItem('token')
    const instanceConfig = config
    instanceConfig.headers = {
      responseType: 'json' as const,
      ...(token && {
        Authorization: `Bearer ${token}`,
        secretKey: config?.headers?.secretKey,
      }),
    }
    instanceConfig.timeout = requestTimeout
    return instanceConfig
  },
  (error) => {
    Promise.reject(error)
  },
)

axiosInstanceConnection.interceptors.response.use(async (response: AxiosResponse) => {
  const { data } = response || {}

  const url = JSON.parse(response?.config?.data || `{}`)?.url || ''

  if (!isEmpty(url)) {
    const urlDecode = Buffer.Buffer.from(url, 'base64').toString('ascii')
    const refreshToken = localStorage.getItem('refreshToken')

    if (urlDecode.includes('refresh') && !isEmpty(refreshToken)) {
      if (data?.statuscode) {
        if (data.statuscode === 200) {
          return response
        }
        if (data.statuscode === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          window.location.href = '/login'
        }
      }
    }
  }

  if (data?.statuscode) {
    if (['TokenExpiredError', 'JsonWebTokenError'].includes(data?.error?.name)) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('tokenM4B')
      localStorage.removeItem('user')
      window.location.href = '/login'
    }

    if (data.statuscode === 200) {
      return response
    }
    if (data.statuscode === 401) {
      const refreshToken = localStorage.getItem('refreshToken')

      if (refreshToken) {
        const {
          statuscode,
          token,
          refreshToken: newRefreshToken,
        } = await post<RefreshTokenRequest, RefreshTokenReponse>('refresh', {
          refreshToken,
        })

        if (statuscode === 200) {
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', newRefreshToken)
          return response
        }

        if (statuscode === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('tokenM4B')
        }
      }
    }
  }

  return response
})

export default axiosInstanceConnection
