import { useState, useEffect, useCallback } from 'react'

import moment from 'moment'
import { useQuery } from 'react-query'

import { MiniappLogKeys, LogColors } from 'constants/commonValues'
import { DateFormat_MMDDYYYY } from 'constants/dateFormats'
import { get } from 'utils/Api.miniservice'

import { ExtendMiniappLogFigures, GetCurrentDateStatisticResponse } from '../../model'

const useCurrentDateStatistic = () => {
  const fetchData = () => {
    const currentDate = moment().format(DateFormat_MMDDYYYY)
    const request = {
      startDate: currentDate,
      endDate: currentDate,
    }
    return get<GetCurrentDateStatisticResponse>('barchart', request)
  }

  const [data, setData] = useState<ExtendMiniappLogFigures[]>([])

  const { isFetching, isLoading } = useQuery(['current-date-log'], fetchData, {
    refetchInterval: 40 * 1000,
    onSuccess: ({ data: respData }) => {
      const mappedData = respData?.map((miniapp) => ({
        ...miniapp,
        sum: (miniapp?.warn || 0) + (miniapp?.info || 0) + (miniapp?.error || 0),
      }))
      setData(mappedData)
    },
    retry: false,
  })

  const getNameMiniappById = (id: string) => (id ? id?.split('.')?.at(-1) : '')

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const [currentMiniappCData, setCurrentMiniappCData] = useState<{ name: string; value: number }[]>(
    [],
  )

  const getCurrentMiniappData = useCallback(
    (index?: number) => {
      const currentMiniapp: ExtendMiniappLogFigures = data?.[index || activeIndex]
      type MnaKeyType = keyof ExtendMiniappLogFigures
      const mnaCData = Object.keys(currentMiniapp || {})
        ?.map((key) => {
          const remap = { name: key as string, value: currentMiniapp[key as MnaKeyType] as number }
          return remap
        })
        .filter((mna) => MiniappLogKeys.includes(mna?.name))
      setCurrentMiniappCData(mnaCData)
    },
    [activeIndex, data],
  )

  useEffect(() => {
    getCurrentMiniappData()
  }, [activeIndex, getCurrentMiniappData])

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index)
    getCurrentMiniappData(index)
  }

  return {
    data,
    setData,
    activeIndex,
    currentMiniappCData,
    onPieEnter,
    MiniappLogKeys,
    LogColors,
    getNameMiniappById,
    isFetching,
    isLoading,
  }
}

export default useCurrentDateStatistic
