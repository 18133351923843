import React, { FC } from 'react'

import { Col, DatePicker, Row, Spin, Typography } from 'antd'
import dayjs from 'dayjs'
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
} from 'recharts'

import { LogColors, MiniappLogKeys } from 'constants/commonValues'

import CustomChartToolTip from '../CustomChartToolTip/CustomChartToolTip'

import { useSystemStatistic } from './useSystemStatistic'

const SystemStatistic: FC = () => {
  const { onChangeYear, selectedYear, data, total, isLoading, isFetching } = useSystemStatistic()

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomBarTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { payload: mnaInfo } = payload?.at(0) || {}
      return <CustomChartToolTip mnaInfo={mnaInfo} />
    }
    return null
  }

  return (
    <Spin spinning={isLoading || isFetching}>
      <Row>
        <Col span={12} offset={1}>
          <Typography.Title level={5}>
            Statistics of the Year:{' '}
            <i>
              {dayjs().year()} ~({Math.round(total)}) logs
            </i>
          </Typography.Title>
        </Col>
        <Col span={11}>
          <DatePicker
            disabled={isLoading || isFetching}
            allowClear={false}
            value={selectedYear}
            picker="year"
            style={{ float: 'right', marginRight: 20 }}
            onSelect={onChangeYear}
          />
        </Col>
      </Row>

      <ResponsiveContainer height={300} minWidth={500}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis />
          <YAxis />
          <Legend />
          {/* <Area type="monotone" dataKey="warn" fill={LogColors[MiniappLogKeys.indexOf('warn')]} stroke={LogColors[MiniappLogKeys.indexOf('warn')]} />
        <Area type="monotone" dataKey="info" fill={LogColors[MiniappLogKeys.indexOf('info')]} stroke={LogColors[MiniappLogKeys.indexOf('info')]} />
        <Area type="monotone" dataKey="error" fill={LogColors[MiniappLogKeys.indexOf('error')]} stroke={LogColors[MiniappLogKeys.indexOf('error')]} /> */}
          <Tooltip content={<CustomBarTooltip />} />
          <Bar dataKey="error" barSize={20} fill={LogColors[MiniappLogKeys.indexOf('error')]} />
          <Bar dataKey="warn" barSize={20} fill={LogColors[MiniappLogKeys.indexOf('warn')]} />
          <Bar dataKey="info" barSize={20} fill={LogColors[MiniappLogKeys.indexOf('info')]} />
        </ComposedChart>
      </ResponsiveContainer>
    </Spin>
  )
}

export default SystemStatistic
