import React, { FC, ReactNode } from 'react'

import CloseCircleTwoTone from '@ant-design/icons/CloseCircleTwoTone'
import EditTwoTone from '@ant-design/icons/EditTwoTone'
import EyeTwoTone from '@ant-design/icons/EyeTwoTone'
import { Space, Tooltip } from 'antd'
import { isEmpty } from 'lodash'

type ActionUserProps = {
  // userId?: string;
  onEdit?: VoidFunction
  onView?: VoidFunction
  onDisable?: VoidFunction
  spaceSize?: number
  // colorTooltip?: string;
  customActions?: ActionTooltip[]
}

type ActionTooltip = {
  tooltip: string
  colorTooltip?: string
  icon?: ReactNode
}

const ActionUser: FC<ActionUserProps> = ({
  onEdit,
  onDisable,
  onView,
  spaceSize,
  customActions,
}) => {
  const actions: ActionTooltip[] =
    customActions && !isEmpty(customActions)
      ? customActions
      : [
          {
            tooltip: 'Edit Account',
            icon: <EditTwoTone onClick={onEdit} />,
          },
          {
            tooltip: 'Disable Account',
            icon: <CloseCircleTwoTone onClick={onDisable} />,
          },
          {
            tooltip: 'View Account',
            icon: <EyeTwoTone onClick={onView} />,
          },
        ]

  return (
    <Space direction="horizontal" size={spaceSize}>
      {actions.map((action, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tooltip title={action.tooltip} color={action.colorTooltip || 'cyan'} key={index}>
          {action.icon}
        </Tooltip>
      ))}
    </Space>
  )
}

export default ActionUser
export type { ActionUserProps }
