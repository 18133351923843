import React, { FC } from 'react'

import { EyeTwoTone, CopyTwoTone } from '@ant-design/icons'
import styled from '@emotion/styled'
import { TableColumnsType, Space, Tooltip, Table, Card } from 'antd'
import dayjs from 'dayjs'

import { DateFormat_DDMMYYHHmm } from 'constants/dateFormats'
import { remoteLoggerRouter } from 'modules/RemoteLogger/RemoteLogger'

import { safeJsonParse } from '../../../../../../utils/isJson'
import { convertMillisecondsToHoursMinutesSeconds } from '../../../../../../utils/time'
import { remoteLoggerDetailRouter } from '../../../RemoteLoggerDetail'
import { Log } from '../../model'
import { NetworkFilterCondition } from '../../useNetworkLogger'
import RequestRateProgress from '../RequestRateProgress'

import useGroupApiTable from './useGroupTable'

const ReqDuration = styled.span`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: oblique;
  font-size: 0.85em;
`
export interface GroupApiTableProps {
  apiname?: string
  filterCond?: NetworkFilterCondition
  allPerformanceLogFlag?: boolean
}

const GroupApiTable: FC<GroupApiTableProps> = ({ apiname, filterCond, allPerformanceLogFlag }) => {
  const {
    data,
    isLoading,
    isRefetching,
    handleTableChange,
    tableParams,
    contextHolder,
    copy,
    messageApi,
  } = useGroupApiTable({
    apiname,
    allPerformanceLogFlag,
    filterCond,
  })

  const logColumns: TableColumnsType<Log> = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (createdDate) => dayjs(createdDate).format(DateFormat_DDMMYYHHmm),
    },
    {
      title: 'Level',
      dataIndex: 'msg',
      render: (msg) => {
        const requestDuration = safeJsonParse(msg)?.requestDuration
        return requestDuration ? <RequestRateProgress requestDuration={requestDuration} /> : ''
      },
    },
    {
      title: 'Duration',
      dataIndex: 'msg',
      render: (msg) => {
        const requestDuration = safeJsonParse(msg)?.requestDuration
        return requestDuration ? (
          <ReqDuration>{convertMillisecondsToHoursMinutesSeconds(requestDuration)}</ReqDuration>
        ) : (
          ''
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'publicid',
      fixed: true,
      render: (publicid) => {
        const urlDetail = [
          window.location.origin,
          remoteLoggerRouter,
          remoteLoggerDetailRouter.replaceAll(':idLog', publicid),
        ].join('/')
        return (
          <Space direction="horizontal" size={10}>
            <Tooltip title="View detail log" color="cyan">
              <EyeTwoTone onClick={() => window.open(urlDetail, '_blank')} />
            </Tooltip>
            <Tooltip title="Copy link" color="cyan">
              <CopyTwoTone
                onClick={() => {
                  copy(urlDetail)
                  messageApi.success({
                    content: 'Copied link to Clipboard',
                    duration: 2,
                  })
                }}
              />
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  return (
    <Card>
      {contextHolder}
      <Table
        // bordered
        rowKey={(record, dataIndex) => `${record?.apiname}${dataIndex}`}
        columns={logColumns}
        dataSource={data?.data?.at(0)?.data}
        loading={isLoading || isRefetching}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        size="small"
      />
    </Card>
  )
}
export default GroupApiTable
