import React from 'react'

import styled from '@emotion/styled'
import { Tag, Typography } from 'antd'
import { Play } from 'phosphor-react'

import { FallInPlace } from 'components/motion'

interface FeatureItemProps {
  className?: string
  title: string
  description: string
  video?: string
  isComingSoon?: boolean
}

const VideoWrapper = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginBottom: props.theme.sizeLG,
}))

const VideoBackdrop = styled.div((props) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: props.theme.colorPrimaryBg,
  borderRadius: props.theme.borderRadiusLG,
  transform: 'scale(0.96) translateY(4.1%)',
  zIndex: -1,
}))

const Video = styled.video((props) => ({
  width: '100%',
  backgroundColor: props.theme.colorPrimaryBg,
  borderRadius: props.theme.borderRadiusLG,
  overflow: 'hidden',
}))

const ComingSoonTag = styled(Tag)((props) => ({
  marginBottom: props.theme.margin,
}))

const PlayButton = styled.div<{ show: boolean }>((props) => ({
  position: 'absolute',
  width: 64,
  height: 64,
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: props.theme.colorWhite,
  opacity: props.show ? 1 : 0,
  transition: 'opacity 0.2s ease-in-out',
}))

const FeatureItem: React.FC<FeatureItemProps> = ({
  className,
  title,
  description,
  video,
  isComingSoon,
}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [isHover, setIsHover] = React.useState(false)

  React.useEffect(() => {
    if (isHover && videoRef.current) {
      videoRef.current.play()
    } else if (videoRef.current) {
      videoRef.current.pause()
    }
  }, [isHover])

  return (
    <div className={className}>
      <FallInPlace>
        {video && (
          <VideoWrapper
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <Video muted playsInline loop ref={videoRef}>
              <source src={video} type="video/webm" />
            </Video>
            <PlayButton show={!isHover}>
              <Play size={32} weight="duotone" />
            </PlayButton>
            <VideoBackdrop />
          </VideoWrapper>
        )}
        {isComingSoon && <ComingSoonTag color="pink">Coming Soon</ComingSoonTag>}
        <Typography.Title level={3}>{title}</Typography.Title>
        <Typography.Paragraph type="secondary" style={{ fontSize: 16 }}>
          {description}
        </Typography.Paragraph>
      </FallInPlace>
    </div>
  )
}

export default FeatureItem
