import React, { useEffect } from 'react'

import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'

import AppFooter from 'components/AppFooter'
import AuthForm from 'components/AuthForm'
import { useAuthContext } from 'contexts/AuthContext'

const Wrapper = styled.div({})

const Container = styled.div((props) => ({
  height: 'calc(100vh - 128px)',
  padding: props.theme.padding,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const FormWrapper = styled.div((props) => ({
  backgroundColor: props.theme.colorBgContainer,
  borderRadius: props.theme.borderRadiusLG,
  boxShadow: props.theme.boxShadow,
  margin: '0px auto',
}))

const Login = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { user } = useAuthContext()

  useEffect(() => {
    if (state?.session && user) {
      navigate(`/connect?session=${state?.session}`, {
        replace: true,
        state: { dataSession: state?.dataSession },
      })
    } else if (user) navigate('/dashboard', { replace: true })
  }, [navigate, state, user])

  return (
    <Wrapper>
      <Container>
        <FormWrapper>
          <AuthForm />
        </FormWrapper>
      </Container>
      <AppFooter />
    </Wrapper>
  )
}

export default Login
