import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Button, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import mq from 'utils/mediaQuery'

interface LandingHeroProps {
  className?: string
}

const HeroBackground = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: props.theme.colorBgContainer,
  textAlign: 'center',
  padding: `${props.theme.paddingXL}px ${props.theme.padding}px`,
}))

const Title = styled(Typography.Title)((props) => ({
  fontWeight: '700 !important',
  maxWidth: 560,
  marginTop: props.theme.sizeXXL,
  fontSize: '2rem !important',
  [mq('md')]: {
    maxWidth: 760,
    fontSize: '2.8rem !important',
  },
}))

const Description = styled(Typography.Paragraph)({
  maxWidth: 900,
  fontSize: '1rem',
  [mq('md')]: {
    fontSize: '1.15rem',
  },
})

const CTAWrapper = styled(Space)((props) => ({
  marginTop: props.theme.sizeXL,
  marginBottom: props.theme.sizeXXL,
  paddingBottom: props.theme.sizeMD,
}))

const ProductPreview = styled.video((props) => ({
  maxWidth: 800,
  borderRadius: props.theme.borderRadiusLG,
  overflow: 'hidden',
  backgroundColor: props.theme.colorBgContainer,
  marginTop: props.theme.sizeLG,
  marginBottom: props.theme.sizeLG,
  // background: `url(images/screenshot.png) 50% 50% / cover no-repeat`,
  // backgroundSize: 'cover',
  boxShadow: props.theme.boxShadowSecondary,
  width: '100%',
  objectFit: 'fill',
}))

const LandingHero: FC<LandingHeroProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <HeroBackground className={className}>
      <Title>{t('landing.hero.title')}</Title>
      <Description type="secondary">{t('landing.hero.description')}</Description>

      <CTAWrapper size="large">
        <Link to="dashboard">
          <Button size="large" type="primary">
            {t('landing.hero.primaryCta')}
          </Button>
        </Link>
        {/* <Link to="documentation">
          <Button size="large">{t('landing.hero.secondaryCta')}</Button>
        </Link> */}
      </CTAWrapper>
      <ProductPreview autoPlay muted loop playsInline>
        <source src="/videos/hero.webm" type="video/webm" />
      </ProductPreview>
    </HeroBackground>
  )
}

export default LandingHero
