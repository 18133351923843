import React, { FC } from 'react'

import { ReactFlowProvider } from 'react-flow-renderer'
import { createBrowserRouter, RouterProvider, RouterProviderProps } from 'react-router-dom'

import RootLayout from 'components/RootLayout'
import Connect from 'modules/Connect'
import Documentation from 'modules/Documentation'
import LandingPage from 'modules/LandingPage'
import Login from 'modules/Login'
import { m4bUserDetailRouter } from 'modules/RemoteLogger/modules/M4bUserDetail/M4bUserDetail'
import { networkLoggerRouter } from 'modules/RemoteLogger/modules/NetworkLogger'
import { remoteLoggerDetailRouter } from 'modules/RemoteLogger/modules/RemoteLoggerDetail/RemoteLoggerDetail'
import { remoteLoggerRoleManagementRouter } from 'modules/RemoteLogger/modules/RemoteLoggerRole/RemoteLoggerRoleManagement'
import { remoteLoggerStatisticRouter } from 'modules/RemoteLogger/modules/RemoteLoggerStatistic'
import { remoteLoggerTableRouter } from 'modules/RemoteLogger/modules/RemoteLoggerTable/RemoteLoggerTable'
import { remoteLoggerUserManagementRouter } from 'modules/RemoteLogger/modules/RemoteLoggerUser'
import { remoteLoggerRouter } from 'modules/RemoteLogger/RemoteLogger'

const Dashboard = React.lazy(() => import('modules/Dashboard'))
const AppList = React.lazy(() => import('modules/Dashboard/AppList'))
const NewApp = React.lazy(() => import('modules/Dashboard/NewApp'))
const Admin = React.lazy(() => import('modules/Dashboard/Admin'))

const Project = React.lazy(() => import('modules/Project'))
const Builder = React.lazy(() => import('modules/Project/modules/Builder'))
const Deploy = React.lazy(() => import('modules/Project/modules/Deploy'))
const AppVersions = React.lazy(() => import('modules/Project/modules/Deploy/modules/AppVersions'))
const Permissions = React.lazy(() => import('modules/Project/modules/Deploy/modules/Permissions'))
const Analytics = React.lazy(() => import('modules/Project/modules/Deploy/modules/Analytics'))
const MACConnect = React.lazy(() => import('modules/Project/modules/Deploy/modules/MACConnect'))
const Preview = React.lazy(() => import('modules/Project/modules/Preview'))
const Settings = React.lazy(() => import('modules/Project/modules/Settings'))
const General = React.lazy(() => import('modules/Project/modules/Settings/modules/General'))
const Theme = React.lazy(() => import('modules/Project/modules/Settings/modules/Theme'))
const AppAssets = React.lazy(() => import('modules/Project/modules/Settings/modules/AppAssets'))
const Config = React.lazy(() => import('modules/Project/modules/Settings/modules/Config'))
const Collaborators = React.lazy(
  () => import('modules/Project/modules/Settings/modules/Collaborators'),
)

const ApiServices = React.lazy(() => import('modules/Project/modules/ApiServices'))
const RequestList = React.lazy(
  () => import('modules/Project/modules/ApiServices/modules/RequestList'),
)
const ConnectRequest = React.lazy(
  () => import('modules/Project/modules/ApiServices/modules/ConnectRequest'),
)

const RemoteLogger = React.lazy(() => import('modules/RemoteLogger'))
const M4bUserDetail = React.lazy(
  () => import('modules/RemoteLogger/modules/M4bUserDetail/M4bUserDetail'),
)
const NetworkLogger = React.lazy(() => import('modules/RemoteLogger/modules/NetworkLogger'))
const LoggerOverview = React.lazy(() => import('modules/RemoteLogger/modules/Overview/overview'))
const RemoteLoggerDetail = React.lazy(
  () => import('modules/RemoteLogger/modules/RemoteLoggerDetail/RemoteLoggerDetail'),
)
const RemoteLoggerRoleManagement = React.lazy(
  () => import('modules/RemoteLogger/modules/RemoteLoggerRole'),
)
const RemoteLoggerStatistic = React.lazy(
  () => import('modules/RemoteLogger/modules/RemoteLoggerStatistic'),
)
const RemoteLoggerTable = React.lazy(
  () => import('modules/RemoteLogger/modules/RemoteLoggerTable/RemoteLoggerTable'),
)
const RemoteLoggerUserManagement = React.lazy(
  () => import('modules/RemoteLogger/modules/RemoteLoggerUser'),
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/connect',
        element: <Connect />,
      },
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: '/documentation',
        element: <Documentation />,
      },
      {
        path: '/dashboard',
        element: (
          <React.Suspense fallback={null}>
            <Dashboard />
          </React.Suspense>
        ),
        children: [
          {
            path: '',
            element: (
              <React.Suspense fallback={null}>
                <AppList />
              </React.Suspense>
            ),
          },
          {
            path: 'new-app',
            element: (
              <React.Suspense fallback={null}>
                <NewApp />
              </React.Suspense>
            ),
          },
          {
            path: 'admin-only-please',
            element: (
              <React.Suspense fallback={null}>
                <Admin />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: '/project/:projectId',
        element: (
          <React.Suspense fallback={null}>
            <Project />
          </React.Suspense>
        ),
        children: [
          {
            path: '',
            element: (
              <React.Suspense fallback={null}>
                <Builder />
              </React.Suspense>
            ),
          },
          {
            path: 'deployment',
            element: (
              <React.Suspense fallback={null}>
                <Deploy />
              </React.Suspense>
            ),
            children: [
              {
                path: '',
                element: (
                  <React.Suspense fallback={null}>
                    <AppVersions />
                  </React.Suspense>
                ),
              },
              {
                path: 'permissions',
                element: (
                  <React.Suspense fallback={null}>
                    <Permissions />
                  </React.Suspense>
                ),
              },
              {
                path: 'connect',
                element: (
                  <React.Suspense fallback={null}>
                    <MACConnect />
                  </React.Suspense>
                ),
              },
              {
                path: 'analytics',
                element: (
                  <React.Suspense fallback={null}>
                    <Analytics />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: 'api-services',
            element: (
              <React.Suspense fallback={null}>
                <ApiServices />
              </React.Suspense>
            ),
            children: [
              {
                path: '',
                element: (
                  <React.Suspense fallback={null}>
                    <RequestList />
                  </React.Suspense>
                ),
              },
              {
                path: ':requestType',
                element: (
                  <React.Suspense fallback={null}>
                    <ReactFlowProvider>
                      <ConnectRequest />
                    </ReactFlowProvider>
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: 'settings',
            element: (
              <React.Suspense fallback={null}>
                <Settings />
              </React.Suspense>
            ),
            children: [
              {
                path: '',
                element: (
                  <React.Suspense fallback={null}>
                    <General />
                  </React.Suspense>
                ),
              },
              {
                path: 'theme',
                element: (
                  <React.Suspense fallback={null}>
                    <Theme />
                  </React.Suspense>
                ),
              },
              {
                path: 'assets',
                element: (
                  <React.Suspense fallback={null}>
                    <AppAssets />
                  </React.Suspense>
                ),
              },
              {
                path: 'config',
                element: (
                  <React.Suspense fallback={null}>
                    <Config />
                  </React.Suspense>
                ),
              },
              {
                path: 'collaborators',
                element: (
                  <React.Suspense fallback={null}>
                    <Collaborators />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: 'preview',
            element: (
              <React.Suspense fallback={null}>
                <Preview />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: remoteLoggerRouter,
        element: (
          <React.Suspense fallback={null}>
            <RemoteLogger />
          </React.Suspense>
        ),
        children: [
          {
            path: '',
            element: (
              <React.Suspense fallback={null}>
                <LoggerOverview />
              </React.Suspense>
            ),
          },
          {
            path: remoteLoggerTableRouter,
            element: (
              <React.Suspense fallback={null}>
                <RemoteLoggerTable />
              </React.Suspense>
            ),
          },
          {
            path: remoteLoggerDetailRouter,
            element: (
              <React.Suspense fallback={null}>
                <RemoteLoggerDetail />
              </React.Suspense>
            ),
          },
          {
            path: remoteLoggerStatisticRouter,
            element: (
              <React.Suspense fallback={null}>
                <RemoteLoggerStatistic />
              </React.Suspense>
            ),
          },
          {
            path: remoteLoggerRoleManagementRouter,
            element: (
              <React.Suspense fallback={null}>
                <RemoteLoggerRoleManagement />
              </React.Suspense>
            ),
          },
          {
            path: remoteLoggerUserManagementRouter,
            element: (
              <React.Suspense fallback={null}>
                <RemoteLoggerUserManagement />
              </React.Suspense>
            ),
          },
          {
            path: m4bUserDetailRouter,
            element: (
              <React.Suspense fallback={null}>
                <M4bUserDetail />
              </React.Suspense>
            ),
          },
          {
            path: networkLoggerRouter,
            element: (
              <React.Suspense fallback={null}>
                <NetworkLogger />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
])

const AppRouter: FC<Omit<RouterProviderProps, 'router'>> = (props) => (
  <RouterProvider router={router} {...props} />
)

export default AppRouter
