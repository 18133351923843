import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import { Log } from 'modules/RemoteLogger/modules/NetworkLogger/model'

export interface NetworkLoggerContextValue {
  currentGroupedlogs: Log[]
  setCurrentGroupedlogs: Dispatch<SetStateAction<Log[]>>
}
export const NetworkLoggerContext = React.createContext<NetworkLoggerContextValue>({
  currentGroupedlogs: [],
  setCurrentGroupedlogs() {},
})
export const NetworkLoggerProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentGroupedlogs, setCurrentGroupedlogs] = useState<Log[]>([])
  return (
    <NetworkLoggerContext.Provider value={{ currentGroupedlogs, setCurrentGroupedlogs }}>
      {children}
    </NetworkLoggerContext.Provider>
  )
}
export default NetworkLoggerContext
