import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Space, Typography } from 'antd'

export interface AppLogoProps {
  className?: string
  onlyIcon?: boolean
}

const Wrapper = styled(Space)({
  alignItems: 'center',
  h5: {
    marginBottom: 0,
    color: '#fff',
    lineHeight: 1.2,
  },
})

const Icon = styled.img({
  width: 40,
  height: 40,
  borderRadius: 4,
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const AppLogo: FC<AppLogoProps> = ({ className, onlyIcon = false }) => (
  <Wrapper className={className}>
    <Icon src="/images/momo-logo-reverse.svg" />
    {!onlyIcon && <Typography.Title level={5}>MIMO</Typography.Title>}
  </Wrapper>
)

export default AppLogo
