import React, { FC } from 'react'

import { Drawer, Space, Button, DatePicker, Form, Input, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { EActionType } from '../../useRLRoleManagement'

import useRoleInfoDrawer from './useRoleInfoDrawer'

type RoleInfoDrawerProps = {
  roleId?: string
  open: EActionType | null
  onClose: () => void
}

const RoleInfoDrawer: FC<RoleInfoDrawerProps> = ({ open, onClose, roleId }) => {
  const { form, handleSubmit, contextHolder, isLoading, isRefetching } = useRoleInfoDrawer({
    open,
    roleId,
    onClose,
  })

  return (
    <Drawer
      title={open?.toString()}
      placement="right"
      size="large"
      onClose={onClose}
      open={!!open}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          {open !== EActionType.view && (
            <Button type="primary" onClick={handleSubmit}>
              {open}
            </Button>
          )}
        </Space>
      }
    >
      <Spin spinning={isLoading || isRefetching}>
        {contextHolder}
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          disabled={[EActionType.view, EActionType.disable, null].includes(open)}
        >
          <Form.Item
            label="Role name"
            name="name"
            rules={[{ required: true, message: 'Please input role name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea
              rows={4}
              disabled={[EActionType.view, EActionType.disable, null].includes(open)}
            />
          </Form.Item>
          {open !== EActionType.create && (
            <>
              <Form.Item label="Created At" name="created_at">
                <DatePicker showTime open={false} allowClear={false} inputReadOnly />
              </Form.Item>
              <Form.Item label="Updated At" name="updated_at">
                <DatePicker showTime open={false} allowClear={false} inputReadOnly />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Drawer>
  )
}

export default RoleInfoDrawer
export type { RoleInfoDrawerProps }
