import React, { useState } from 'react'

import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import useLocalStorage from './useLocalStorage'

export default function useUndoableState<T>(init: T, key: string, debouncePeriod?: number) {
  const [index, setIndex] = React.useState(0)
  const [states, setStates] = useState<T[]>([init])
  const [state, setRawState] = useLocalStorage<T>({
    key: key || 'undoableState',
    defaultValue: init,
    getInitialValueInEffect: true,
    onGetInitialValue: (initialValue) => {
      setStates([initialValue || init])
    },
  })

  const debouncedSetStates = React.useMemo(
    () =>
      debounce((value: T) => {
        if (!states) return
        const copy = [...states]
        copy.length = index + 1 // delete all history after index
        copy.push(value)
        setStates(copy)
        setIndex(copy.length - 1)
      }, debouncePeriod ?? 100),
    [states, debouncePeriod, index, setStates],
  )

  const setState = (value: T | ((prevState: T) => T)): void => {
    if (value instanceof Function) {
      setRawState((current) => {
        const result = value(current)
        debouncedSetStates?.(result)
        return result
      })
    } else {
      if (isEqual(state, value)) {
        return
      }
      setRawState(value)
      debouncedSetStates?.(value)
    }
  }

  const resetState = (initial: T = init): void => {
    setIndex(0)
    setRawState(initial)
    setStates([initial])
  }

  const wipeHistory = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (state && (state as any)?.length) {
      setStates([state])
      setIndex(0)
    }
  }

  const undoable = index > 0

  const redoable = !!states && index < states.length - 1

  const undo = (steps = 1): void => {
    if (!states || !undoable) return
    const newIndex = Math.max(0, Number(index) - (Number(steps) || 1))
    setIndex(newIndex)
    setRawState(states[newIndex])
  }

  const redo = (steps = 1): void => {
    if (!states || !redoable) return
    const newIndex = Math.min(states.length - 1, Number(index) + (Number(steps) || 1))
    setIndex(newIndex)
    setRawState(states[newIndex])
  }

  return {
    state,
    setState,
    resetState,
    index,
    undo,
    redo,
    undoable,
    redoable,
    wipeHistory,
  }
}
