import React, { FC, ReactNode } from 'react'

import EditTwoTone from '@ant-design/icons/EditTwoTone'
import EyeTwoTone from '@ant-design/icons/EyeTwoTone'
import { Space, Tooltip } from 'antd'
import { isEmpty } from 'lodash'

type ActionRoleProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  roleId?: string
  onEdit?: VoidFunction
  onView?: VoidFunction
  spaceSize?: number
  // eslint-disable-next-line react/no-unused-prop-types
  colorTooltip?: string
  customActions?: ActionTooltip[]
}

type ActionTooltip = {
  tooltip: string
  colorTooltip?: string
  icon?: ReactNode
}

const ActionRole: FC<ActionRoleProps> = ({ onEdit, onView, spaceSize, customActions }) => {
  const actions: ActionTooltip[] =
    customActions && !isEmpty(customActions)
      ? customActions
      : [
          {
            tooltip: 'Edit Account',
            icon: <EditTwoTone onClick={onEdit} />,
          },
          // {
          //   tooltip: 'Disable Account',
          //   icon: <CloseCircleTwoTone onClick={onDisable} />,
          // },
          {
            tooltip: 'View Account',
            icon: <EyeTwoTone onClick={onView} />,
          },
        ]

  return (
    <Space direction="horizontal" size={spaceSize}>
      {actions.map((action, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tooltip title={action.tooltip} color={action.colorTooltip || 'cyan'} key={index}>
          {action.icon}
        </Tooltip>
      ))}
    </Space>
  )
}

export default ActionRole
export type { ActionRoleProps }
