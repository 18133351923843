// tạo modal context có thể sử dụng ở bất cứ đâu trong app

import React, { createContext, FC, useState } from 'react'

export interface ModalProviderProps {
  children: React.ReactNode
}

export interface ModalCustomProps {
  key: string
  component: React.ReactNode
}

export const ModalContext = createContext({
  showModal: (modal: ModalCustomProps) => {},
  hideModal: (key: string) => {},
})

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<ModalCustomProps[]>([])

  const showModal = (modal: ModalCustomProps) => {
    const isExist = modals.find((item) => item.key === modal.key)
    if (!isExist) {
      setModals([...modals, modal])
    }
  }

  const hideModal = (key: string) => {
    setModals(modals.filter((modal) => modal.key !== key))
  }

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
      }}
    >
      {children}
      {modals.map((modal) => (
        <div key={modal.key}>{modal.component}</div>
      ))}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => React.useContext(ModalContext)
