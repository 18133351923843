import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Button, Dropdown, Avatar as AntdAvatar } from 'antd'
import BoringAvatar from 'boring-avatars'
import { SignOut, ChartLine } from 'phosphor-react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useAuthContext } from 'contexts/AuthContext'
import isTemplateAdmin from 'utils/isTemplateAdmin'
import mq from 'utils/mediaQuery'

const StyledButton = styled(Button)((props) => ({
  padding: `0px ${props.theme.paddingSM}px !important`,
  display: 'flex',
  alignItems: 'center',
}))

const Username = styled.span((props) => ({
  fontSize: 14,
  marginLeft: props.theme.paddingXS,
  display: 'none !important',
  [mq('md')]: {
    display: 'block !important',
  },
}))

const AuthButton: FC = () => {
  const { user, logout } = useAuthContext()
  const navigate = useNavigate()
  const { projectId } = useParams()

  if (user) {
    return (
      <Dropdown
        placement="bottomRight"
        menu={{
          activeKey: projectId,
          items: [
            {
              key: 'profiles',
              style: { paddingLeft: 0 },
              label: <Username>{user.firebaseUser?.displayName || user.username}</Username>,
              disabled: true,
            },
            {
              type: 'divider',
            },
            ...((isTemplateAdmin()
              ? [
                  {
                    key: 'logger',
                    label: 'Remote Logger',
                    onClick: () => navigate('/logger'),
                    icon: <ChartLine />,
                  },
                  {
                    type: 'divider',
                  },
                  {
                    key: 'sign-out',
                    label: 'Sign Out',
                    onClick: () => logout?.(),
                    icon: <SignOut />,
                  },
                ]
              : [
                  {
                    key: 'sign-out',
                    label: 'Sign Out',
                    onClick: () => logout?.(),
                    icon: <SignOut />,
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ]) as any),
          ],
        }}
      >
        <StyledButton
          type="primary"
          size="large"
          icon={
            user.firebaseUser?.photoURL ? (
              <AntdAvatar src={user.firebaseUser.photoURL} size={28} />
            ) : (
              <BoringAvatar
                size={28}
                name={user.username}
                variant="beam"
                colors={['#ffffff', '#ffa8d8', '#ff7dc5', '#C73E89', '#971D62']}
              />
            )
          }
        >
          {' '}
        </StyledButton>
      </Dropdown>
    )
  }

  return (
    <Link to="/login">
      <Button type="primary">Sign In</Button>
    </Link>
  )
}

export default AuthButton
