import dayjs from 'dayjs';
import { isNumber } from "lodash";

const convertMillisecondsToHoursMinutesSeconds = (milliseconds: number) => {
  if (!isNumber(milliseconds)) return '';
  return `${dayjs(milliseconds).format('ss.SSS')}ms`;
}

export {
  convertMillisecondsToHoursMinutesSeconds
}