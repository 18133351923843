import { useCallback, useEffect, useState } from 'react'

import { TablePaginationConfig } from 'antd'
import message from 'antd/es/message'
import dayjs from 'dayjs'
import { pickBy } from 'lodash'
import { RangeValue } from 'rc-picker/lib/interface'
import { useQuery } from 'react-query'

import { DateFormat_MMDDYYYY } from 'constants/dateFormats'
import useCopyToClipboard from 'hooks/useClipboard'
import { get } from 'utils/Api.miniservice'

import { GetMiniappsResponse, MiniappDefinition } from '../RemoteLoggerStatistic/model'

import { ApiReportResponse, ApiReportData } from './model'

export type NetworkFilterCondition = {
  miniappid?: string
  search?: string
  filterDate?: [dayjs.Dayjs, dayjs.Dayjs]
  pagination: TablePaginationConfig
}

export const useNetworkLogger = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [valCopy, copy] = useCopyToClipboard()
  const [expanedApi, setExpendedApi] = useState<null | ApiReportData>()
  const [allPerformanceLogFlag, setAllPerformanceLogFlag] = useState<boolean>(false)

  const { data: miniapps, isLoading: isLoadingMiniappData } = useQuery(
    ['get-miniapps'],
    () => get<GetMiniappsResponse>('miniapp'),
    {
      cacheTime: 3 * 60 * 1000,
      retry: false,
    },
  )

  const [filterCondition, setFilterCondition] = useState<NetworkFilterCondition>({
    miniappid: '',
    search: '',
    filterDate: [dayjs().subtract(1, 'days'), dayjs()],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const getAllRequestLogs = async () =>
    get<ApiReportResponse>(
      allPerformanceLogFlag ? 'api-reports/slow' : 'api-reports',
      pickBy({
        startDate: filterCondition.filterDate?.[0].format(DateFormat_MMDDYYYY),
        endDate: filterCondition.filterDate?.[1].format(DateFormat_MMDDYYYY),
        page: filterCondition?.pagination?.current,
        miniAppId: filterCondition.miniappid,
        limit: filterCondition.pagination?.pageSize,
        phone: filterCondition.search,
      }),
    )

  const { data, isLoading, isRefetching, refetch } = useQuery(
    JSON.stringify(filterCondition),
    getAllRequestLogs,
    {
      onSuccess(dataResp: ApiReportResponse) {
        const { total, page: current } = dataResp.data?.at(0)?.metaData?.at(0) || {}
        if (total !== filterCondition.pagination?.total)
          setFilterCondition({
            ...filterCondition,
            pagination: {
              ...filterCondition.pagination,
              current,
              total,
            },
          })
      },
    },
  )

  const handleSearchInput = (searchTerm: string) =>
    setFilterCondition((filterCond) => ({
      ...filterCond,
      search: searchTerm,
    }))

  const onChangeMiniapp = useCallback(
    (miniapp: MiniappDefinition | null) =>
      setFilterCondition((prevState) => ({ ...prevState, miniappid: miniapp?.miniapid })),
    [],
  )
  const onChangeDate = useCallback((selectedRange: RangeValue<dayjs.Dayjs>) => {
    setFilterCondition(
      (prevState: NetworkFilterCondition) =>
        ({
          ...prevState,
          filterDate: selectedRange,
        } as NetworkFilterCondition),
    )
  }, [])

  const onChangeApi = useCallback((value: boolean) => {
    setFilterCondition((filterCond) => ({
      ...filterCond,
      pagination: {
        current: 1,
        ...filterCond.pagination,
      },
    }))
    setAllPerformanceLogFlag(value)
  }, [])

  useEffect(() => {
    refetch()
    setExpendedApi(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterCondition), allPerformanceLogFlag])

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setFilterCondition((currentState) => ({
      ...currentState,
      pagination,
    }))
  }

  return {
    handleSearchInput,
    miniapps,
    isLoadingMiniappData,
    onChangeMiniapp,
    filterCondition,
    onChangeDate,
    data,
    isLoading,
    contextHolder,
    copy,
    messageApi,
    setExpendedApi,
    expanedApi,
    onChangeApi,
    allPerformanceLogFlag,
    handleTableChange,
    valCopy,
    isRefetching,
  }
}
