/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, FilterConfirmProps } from "antd/lib/table/interface";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { get } from "utils/Api.miniservice";

import { GetRoleResponse, Role, TableParams, } from "./model";


export enum EActionType {
  create = 'CREATE', view = 'VIEW', edit = 'EDIT', disable = 'DISABLE'
}

const useRLRoleManagement = () => {
  const navigator = useNavigate();
  const [openDrawer, setDrawer] = useState<EActionType | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = () => get<GetRoleResponse>('role');


  const { isLoading, refetch, data, isRefetching } = useQuery(['rl-role-mnt'], fetchData, {
    onSuccess: (resp) => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: resp?.data?.length,
        },
      });
    },
    refetchInterval: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    retry: false
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue> | any,
    sorter: SorterResult<Role> | SorterResult<Role>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm({ closeDropdown: false });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setTableParams((state) => ({ ...state, filters: {} }));
  };

  const handleSearchInput = (searchTerm: string) => {
    setTableParams((state) => ({ ...state, searchTerm, filters: {} }));
  };

  const handleOpenRoleDrawer = (roleId?: string, actionType: EActionType = EActionType.create) => () => {
    // eslint-disable-next-line default-case
    switch (actionType) {
      case EActionType.create:
        setDrawer(actionType);
        break;
      case EActionType.edit:
      case EActionType.view:
      case EActionType.disable:
        setSelectedRoleId(roleId)
        setDrawer(actionType);
    }
  }

  const handleCloseDrawer = () => {
    setDrawer(null); setSelectedRoleId('');
  }

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, JSON.stringify(tableParams)]);

  return {
    navigator,
    tableParams,
    setTableParams,
    data,
    isLoading,
    isRefetching,
    refetch,
    handleTableChange,
    handleReset,
    handleSearch,
    handleSearchInput,
    openDrawer,
    setDrawer,
    handleOpenRoleDrawer,
    handleCloseDrawer,
    selectedRoleId
  }
}

export default useRLRoleManagement;

