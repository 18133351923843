import React from 'react'

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { theme } from 'antd'
import { IconContext } from 'phosphor-react'

interface ThemeProviderProps {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { token } = theme.useToken()

  return (
    <EmotionThemeProvider theme={token}>
      <IconContext.Provider value={{ size: token.size }}>{children}</IconContext.Provider>
    </EmotionThemeProvider>
  )
}

export default ThemeProvider
