import { useState, useEffect } from 'react'

interface Breakpoints {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}

const breakpoints: Breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1600,
}

const mq = (n: keyof Breakpoints) => {
  const bpArray = Object.keys(breakpoints).map((key) => [
    key,
    breakpoints[key as keyof Breakpoints],
  ])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    windowSize,
    breakpoint: Object.keys(breakpoints).reduce((acc, key) => {
      if ((windowSize?.width || 0) >= breakpoints[key as keyof Breakpoints]) {
        return key
      }
      return acc
    }, 'xxs'),
  }
}

export default mq
