import React, { FC, useCallback } from 'react'

import styled from '@emotion/styled'
import { Button } from 'antd'
import { Translate } from 'phosphor-react'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from 'contexts/AuthContext'
import { useTranslateContext } from 'contexts/TranslateContext'
import mq from 'utils/mediaQuery'

export type TranslateGlobalProps = {
  preffixIcon?: React.ReactNode
}

const StyledButton = styled(Button)((props) => ({
  padding: `0px ${props.theme.paddingSM}px !important`,
  display: 'flex',
  alignItems: 'center',
}))

const Username = styled.span((props) => ({
  fontSize: 14,
  marginLeft: props.theme.paddingXS,
}))

const WrapperButtonContainer = styled.div(() => ({
  display: 'none',
  [mq('md')]: {
    display: 'block',
  },
}))

const TranslateGlobal: FC<TranslateGlobalProps> = ({ preffixIcon }) => {
  const { user } = useAuthContext()
  const { t } = useTranslation()

  const {
    setShow,
    setTranslateData,
    commonLocalizations,
    projectId,
    isMultiLanguage,
    setShowComfirm,
  } = useTranslateContext()

  const onClick = useCallback(() => {
    if (isMultiLanguage) {
      setTranslateData({
        type: 'common',
        payload: commonLocalizations,
        name: 'common',
      })
      setShow(true)
    } else {
      setShowComfirm?.(true)
    }
  }, [isMultiLanguage, commonLocalizations, setShow, setTranslateData, setShowComfirm])

  return user && projectId ? (
    <>
      {preffixIcon}
      <WrapperButtonContainer>
        <StyledButton
          type="primary"
          size="large"
          block
          icon={<Translate size={24} />}
          onClick={onClick}
        >
          <Username>{t('project.builder.multiLanguage')}</Username>
        </StyledButton>
      </WrapperButtonContainer>
    </>
  ) : null
}

export default TranslateGlobal
