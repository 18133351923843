import React, { FC, useContext } from 'react'

import { Card, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, Legend, Bar } from 'recharts'

import NetworkLoggerContext from 'contexts/NetworkLogerContext'
import { safeJsonParse } from 'utils/isJson'

interface GroupApiDetailProps {
  apiName?: string
}
const GroupApiDetail: FC<GroupApiDetailProps> = ({ apiName = '' }) => {
  const { currentGroupedlogs } = useContext(NetworkLoggerContext)
  const Cdata = currentGroupedlogs?.map((log, index) => ({
    requestIndex: index,
    requestDuration: safeJsonParse(log?.msg)?.requestDuration,
  }))
  return (
    <Card type="inner" title={apiName || 'Detail'}>
      {!isEmpty(currentGroupedlogs) && (
        <ResponsiveContainer width={500} height={800}>
          <ComposedChart layout="vertical" width={500} data={Cdata}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" unit="ms" />
            <Tooltip />
            <Legend />
            <Bar dataKey="requestDuration" barSize={15} fill="#413ea0" />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Card>
  )
}
export default GroupApiDetail
