import React from 'react'

import styled from '@emotion/styled'
import { Layout } from 'antd'

interface AppFooterProps {
  className?: string
}

const Footer = styled(Layout.Footer)({
  textAlign: 'center',
  letterSpacing: '0.5px',
  span: {
    margin: '0 8px',
  },
})

const AppFooter: React.FC<AppFooterProps> = ({ className }) => (
  <Footer className={className}>
    <span>Mini App Template</span> • <span>Config Generator</span> •{' '}
    <span>© {new Date().getFullYear()} by Mini App Solutions</span>
  </Footer>
)

export default AppFooter
