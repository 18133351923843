import React, { FC, useMemo } from 'react'

import { UpOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Card, Col, DatePicker, Divider, Dropdown, MenuProps, Row, Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import SearchInput from 'components/SearchInput'
import { DateFormat_MMDDYYYY } from 'constants/dateFormats'
import { NetworkLoggerProvider } from 'contexts/NetworkLogerContext'
import { convertMillisecondsToHoursMinutesSeconds } from 'utils/time'

import { MiniappDefinition } from '../RemoteLoggerStatistic/model'

import GroupApiDetail from './components/GroupApiDetail/GroupApiDetail'
import GroupApiTable from './components/GroupApiTable/GroupApiTable'
import RequestRateProgress from './components/RequestRateProgress'
import { ApiReportData } from './model'
import { useNetworkLogger } from './useNetworkLogger'

const { RangePicker } = DatePicker

const PageContainer = styled.div`
  margin: 12px;
  width: 100%;
`

const ReqDuration = styled.span`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: oblique;
  font-size: 0.85em;
`

const NetworkLogger: FC = () => {
  const {
    handleSearchInput,
    miniapps,
    isLoadingMiniappData,
    onChangeMiniapp,
    filterCondition,
    onChangeDate,
    data,
    contextHolder,
    setExpendedApi,
    expanedApi,
    isLoading,
    allPerformanceLogFlag,
    onChangeApi,
    handleTableChange,
    isRefetching,
  } = useNetworkLogger()

  const columns: ColumnsType<ApiReportData> = [
    {
      title: 'Api Name',
      dataIndex: 'apiname',
    },
    {
      title: 'Level',
      dataIndex: 'avgDuration',
      render: (avgDuration) =>
        avgDuration ? <RequestRateProgress requestDuration={avgDuration} /> : '',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Avg Duration',
      dataIndex: 'avgDuration',
      render: (requestDuration) =>
        requestDuration && (
          <ReqDuration>~{convertMillisecondsToHoursMinutesSeconds(requestDuration)}</ReqDuration>
        ),
    },
  ]

  const expandedRowRender = (record: ApiReportData) => (
    <GroupApiTable
      apiname={record?.apiname}
      filterCond={filterCondition}
      allPerformanceLogFlag={allPerformanceLogFlag}
    />
  )
  const onExpand = (expanded: boolean, record: ApiReportData) => {
    setExpendedApi(expanded ? record : null)
  }

  const menuProps = useMemo(() => {
    const handleMenuClick: MenuProps['onClick'] = (e) => {
      const selectedMna =
        (miniapps?.data?.data || []).find((mna) => mna?.miniapid === e?.key) || null
      onChangeMiniapp(selectedMna)
    }
    const items: MenuProps['items'] = [
      // {
      //   label: ' All Mininapp',
      //   key: 'null',
      // },
      ...(miniapps?.data?.data || []).map((mna: MiniappDefinition) => ({
        label: mna?.name || mna?.miniappname,
        key: mna?.miniapid,
      })),
    ]

    return {
      items,
      onClick: handleMenuClick,
    }
  }, [miniapps?.data?.data, onChangeMiniapp])

  const selectedMnaName = useMemo(() => {
    const selectedMna = (miniapps?.data?.data || []).find(
      (mna: MiniappDefinition) => mna?.miniapid === filterCondition.miniappid,
    )
    return selectedMna?.name || selectedMna?.miniappname || 'All Mininapp'
  }, [filterCondition.miniappid, miniapps?.data?.data])

  return (
    <NetworkLoggerProvider>
      <PageContainer>
        {contextHolder}
        <Card style={{ width: '100%', borderRadius: 8 }}>
          <Row gutter={10}>
            <Col span={7}>
              <SearchInput
                onSearch={handleSearchInput}
                placeholder="Search by phone"
                loading={isLoading || isRefetching}
                withoutDebounce
              />
            </Col>
            <Col span={5} />
            <Col span={12}>
              <Row justify="end" gutter={12} align="middle">
                <Col>
                  <Switch
                    checkedChildren="Only Performance Warning"
                    unCheckedChildren="All Request"
                    checked={allPerformanceLogFlag}
                    onChange={onChangeApi}
                    disabled={isLoading || isRefetching}
                  />
                </Col>
                <Col>
                  <RangePicker
                    disabled={isLoading || isRefetching}
                    allowClear={false}
                    allowEmpty={[false, false]}
                    format={DateFormat_MMDDYYYY}
                    value={filterCondition.filterDate}
                    onChange={onChangeDate}
                  />
                </Col>
                <Col>
                  <Dropdown.Button
                    menu={menuProps}
                    arrow={{ pointAtCenter: true }}
                    icon={<UpOutlined />}
                    loading={isLoadingMiniappData}
                    disabled={isLoading || isRefetching}
                  >
                    {selectedMnaName}
                  </Dropdown.Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider orientation="right" />
          <Row gutter={10}>
            <Col span={15}>
              <Table
                columns={columns}
                rowKey={(record, dataIndex) => `${record?.apiname}${dataIndex}`}
                expandable={{ expandedRowRender, onExpand }}
                dataSource={data?.data?.at(0)?.data || []}
                pagination={filterCondition.pagination}
                loading={isLoading}
                onChange={handleTableChange}
              />
            </Col>
            <Col span={9}>
              <GroupApiDetail apiName={expanedApi?.apiname} />
            </Col>
          </Row>
        </Card>
      </PageContainer>
    </NetworkLoggerProvider>
  )
}

export default NetworkLogger
export const networkLoggerRouter = 'network-log'
