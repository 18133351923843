import React, { FC } from 'react'

import AppFooter from 'components/AppFooter'
import { FallInPlace } from 'components/motion'

import Features from './components/Features'
import LandingHero from './components/LandingHero'
import YourMiniApp from './components/YourMiniApp'

const LandingPage: FC = () => (
  <>
    <FallInPlace initialInView>
      <LandingHero />
    </FallInPlace>
    <Features />
    <YourMiniApp />
    <AppFooter />
  </>
)

export default LandingPage
