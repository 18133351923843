import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Button, Col, Form, Input, Row, Typography } from 'antd'

import { useAuthContext } from 'contexts/AuthContext'

const SignInForm = styled.div((props) => ({
  padding: props.theme.padding,
  'input:-webkit-autofill, input:-webkit-autofill:hover,  input:-webkit-autofill:focus,  input:-webkit-autofill:active':
    {
      '-webkit-box-shadow': '0 0 0 30px #fff7fa inset !important',
    },
}))

const AuthLogo = styled.img((props) => ({
  width: 64,
  height: 64,
  marginBottom: props.theme.marginLG,
}))

const AuthBackground = styled.div((props) => ({
  backgroundImage: 'url(/images/auth-bg.png)',
  backgroundSize: 'contain',
  backgroundPosition: 'center 65%',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#fff7fa',
  minHeight: 400,
  height: '100%',
  borderRadius: props.theme.borderRadiusLG,
  display: 'flex',
  justifyContent: 'center',
}))

const BackgroundTitle = styled(Typography.Title)((props) => ({
  textAlign: 'center',
  margin: `${props.theme.marginXXL}px !important`,
  marginTop: '20% !important',
  color: `${props.theme.colorPrimary} !important`,
}))

const SubmitButton = styled(Button)((props) => ({
  marginTop: `${props.theme.margin}px`,
}))

const SignUpWrapper = styled.div((props) => ({
  marginTop: props.theme.margin,
  display: 'inline-block',
  '.ant-typography': {
    marginBottom: '0px !important',
    display: 'inline-block',
    marginRight: `${props.theme.sizeXXS}px`,
  },
}))

interface LoginValues {
  username: string
  password: string
}

const AuthForm: FC = () => {
  const { login, authenticating } = useAuthContext()

  return (
    <Row>
      <Col span={24} md={12}>
        <SignInForm>
          <AuthLogo src="https://business.momo.vn/sanpham/static/media/icon-momo-logo.09c59c1d7f80cfa2c9517c69d7fcb302.svg" />
          <Typography.Title level={3}>Sign In</Typography.Title>
          <Typography.Paragraph type="secondary">
            Enter your MoMo business account to sync app config and access full template features.
          </Typography.Paragraph>
          <Form<LoginValues>
            layout="vertical"
            requiredMark={false}
            onFinish={(values) => login(values)}
          >
            <Form.Item name="username" label="Username" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>
            <SubmitButton type="primary" htmlType="submit" block loading={authenticating}>
              Log in
            </SubmitButton>
          </Form>
          <SignUpWrapper>
            <Typography.Paragraph type="secondary">{`Don't have an account?`}</Typography.Paragraph>
            <Typography.Link href="https://business.momo.vn/sanpham/auth/signup" target="_blank">
              Register with MoMo Business
            </Typography.Link>
          </SignUpWrapper>
        </SignInForm>
      </Col>
      <Col span={0} md={12}>
        <AuthBackground>
          <BackgroundTitle level={3}>Mini App Template</BackgroundTitle>
        </AuthBackground>
      </Col>
    </Row>
  )
}

export default AuthForm
